import React, { useState, useEffect } from "react";
import AOS from "aos";
import "aos/dist/aos.css";
import Row from "react-bootstrap/Row";
import Container from "react-bootstrap/Container";
import Col from "react-bootstrap/Col";
import Button from "react-bootstrap/Button";
import Form from "react-bootstrap/Form";
import FormControl from "react-bootstrap/FormControl";
import FloatingLabel from "react-bootstrap/FloatingLabel";

import "./StudentProfile.css";
import { Link } from "react-router-dom";
import AlternateSections from "../../Container/Homepage/AlternateSection";
import { UserCredsContext } from "../../ContextApis/UserCredsContext/UserCredsContext";
import { apiCallToken } from "../../Utils/Network";
import { profile_update } from "../../Utils/ApiUrls";

function StudentProfile() {
  const { user_details, set_user_data, _token } =
    React.useContext(UserCredsContext);
  const [toast, setToast] = useState({ msg: "", type: "" });
  const [data, setData] = useState({
    first_name: "",
    last_name: "",
    password: "",
  });

  useEffect(() => {
    const nameArr = user_details.student_name.split(" ");
    if (nameArr.length > 1) {
      setData({ first_name: nameArr[0], last_name: nameArr[1], password: "" });
    } else setData({ ...data, first_name: user_details.student_name });
    AOS.init({
      offset: 200,
      duration: 600,
      easing: "ease-in-sine",
      delay: 100,
    });
    AOS.refresh();
  }, []);

  useEffect(() => {
    if (toast.type) setTimeout(() => setToast({ msg: "", type: "" }), 3000);
  }, [toast]);

  const submitHandler = async (event) => {
    event.preventDefault();
    if (!data.first_name)
      return setToast({ msg: "Please Enter First Name", type: "error" });
    else if (!data.last_name)
      return setToast({ msg: "Please Enter Last Name", type: "error" });
    else if (!data.password)
      return setToast({ msg: "Please Enter Password", type: "error" });

    apiCallToken
      .post(profile_update, data)
      .then((response) => {
        console.log(response);
        if (response.status === 200) {
          setToast({ msg: "Profile updated successfully !", type: "success" });
          const student_name = data.first_name + " " + data.last_name;
          set_user_data({ ...user_details, student_name }, _token);
        } else setToast({ msg: response.data.data, type: "error" });
      })
      .catch((error) => {
        setToast({
          msg: error.response?.data?.message || "Something went wrong !",
          type: "error",
        });
      });
  };

  return (
    <div>
      <div id="snackbar" className={toast.type ? toast.type + " show" : ""}>
        {toast.msg}
      </div>
      <section id="values" className="values" style={{ marginTop: "6%" }}>
        <Container>
          <header className="section-header">
            <p>My Profile</p>
          </header>
          <Form>
            <Row className="mb-3">
              <p style={{ marginTop: 0 }}>
                Membership Type :{" "}
                <strong>
                  {user_details.paid_status === "UNPAID" ? "FREE" : "PAID"}
                </strong>
              </p>
              <Form.Group as={Col} md="6" controlId="validationCustom01">
                <Form.Label>First name</Form.Label>
                <Form.Control
                  required
                  type="text"
                  placeholder="Enter First name"
                  value={data.first_name}
                  onChange={(e) =>
                    setData({ ...data, first_name: e.target.value })
                  }
                />
                <Form.Control.Feedback>Looks good!</Form.Control.Feedback>
              </Form.Group>
              <Form.Group as={Col} md="6" controlId="validationCustom02">
                <Form.Label>Last name</Form.Label>
                <Form.Control
                  required
                  type="text"
                  placeholder="Enter Last name"
                  value={data.last_name}
                  onChange={(e) =>
                    setData({ ...data, last_name: e.target.value })
                  }
                />
                <Form.Control.Feedback>Looks good!</Form.Control.Feedback>
              </Form.Group>
            </Row>
            <Row className="mb-3">
              <Form.Group as={Col} controlId="formGridEmail">
                <Form.Label>Email</Form.Label>
                <Form.Control readOnly value={user_details.email} />
              </Form.Group>

              <Form.Group as={Col} controlId="formGridPassword">
                <Form.Label>Mobile Number</Form.Label>
                <Form.Control readOnly value={user_details.mobile_number} />
              </Form.Group>
            </Row>
            <Form.Group className="mb-4" as={Col} controlId="formGridPassword">
              <Form.Label>Password</Form.Label>
              <Form.Control
                type="password"
                placeholder="Enter Password"
                value={data.password}
                onChange={(e) => setData({ ...data, password: e.target.value })}
              />
            </Form.Group>

            {/* <Form.Group className="mb-3" controlId="formGridAddress1">
              <Form.Label>Address</Form.Label>
              <Form.Control placeholder="1234 Main St" />
            </Form.Group>

            <Form.Group className="mb-3" controlId="formGridAddress2">
              <Form.Label>Address 2</Form.Label>
              <Form.Control placeholder="Apartment, studio, or floor" />
            </Form.Group>

            <Row className="mb-3">
              <Form.Group as={Col} controlId="formGridCity">
                <Form.Label>City</Form.Label>
                <Form.Control />
              </Form.Group>

              <Form.Group as={Col} controlId="formGridState">
                <Form.Label>State</Form.Label>
                <Form.Select defaultValue="Choose...">
                  <option>Choose...</option>
                  <option>...</option>
                </Form.Select>
              </Form.Group>

              <Form.Group as={Col} controlId="formGridZip">
                <Form.Label>Zip</Form.Label>
                <Form.Control />
              </Form.Group>
            </Row>

            <Row className="mb-3">
              <Form.Group as={Col} md="4" controlId="formGridState">
                <Form.Label>Course</Form.Label>
                <Form.Select defaultValue="Choose...">
                  <option>Choose...</option>
                  <option>...</option>
                </Form.Select>
              </Form.Group>

              <Form.Group as={Col} md="4" controlId="formGridState">
                <Form.Label>Branch</Form.Label>
                <Form.Select defaultValue="Choose...">
                  <option>Choose...</option>
                  <option>...</option>
                </Form.Select>
              </Form.Group>

              <Form.Group as={Col} md="4" controlId="formGridState">
                <Form.Label>Batch</Form.Label>
                <Form.Select defaultValue="Choose...">
                  <option>Choose...</option>
                  <option>...</option>
                </Form.Select>
              </Form.Group>
            </Row>

            <Row className="mb-3">
              <Form.Group as={Col} md="4" controlId="formGridState">
                <Form.Label>Educational Loan</Form.Label>
                <Form.Select defaultValue="Choose...">
                  <option>Choose...</option>
                  <option>...</option>
                </Form.Select>
              </Form.Group>

              <Form.Group as={Col} md="4" controlId="formGridState">
                <Form.Label>Student Type</Form.Label>
                <Form.Select defaultValue="Choose...">
                  <option>Choose...</option>
                  <option>...</option>
                </Form.Select>
              </Form.Group>
            </Row>

            <Form.Group className="mb-3" id="formGridCheckbox">
              <Form.Check type="checkbox" label="Edit" />
            </Form.Group> */}

            <Button onClick={submitHandler} variant="primary" type="submit">
              Submit
            </Button>
          </Form>
        </Container>
      </section>
    </div>
  );
}

export default StudentProfile;
