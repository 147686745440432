import React, { useContext, useEffect, useState } from "react";
import LoginPageImage from "../../Assets/Reference/LoginPageImage.png";
import "./Login.css";
import LoginPageBG from "../../Assets/Login/LoginPageBG.png";
import login from "./loginApi";
import LoginUseReducer from "./LoginUseReducer";
import { apiCall, setTokenInHeaders, setApiToken } from "../../Utils/Network";
import { login_url, send_otp, sign_up, verify_otp } from "../../Utils/ApiUrls";
import { UserCredsContext } from "../../ContextApis/UserCredsContext/UserCredsContext";
import { Link, withRouter } from "react-router-dom";
import AOS from "aos";
import "aos/dist/aos.css";
// const initialState = {
//     username: 'kanojiask62@gmail.com',
//     password: 'b@123',
//     isLoading: false,
//     error: '',
//     isLoggedIn: false,
// };

function CreateAccount(props) {
  const { set_user_data, user_details } = useContext(UserCredsContext);
  const [data, setData] = useState({
    first_name: "",
    last_name: "",
    email: "",
    mobile_number: "",
    mobile_otp: "",
    password: "",
  });
  const [otpSent, setOtpSent] = useState(false);
  const [toast, setToast] = useState({ msg: "", type: "" });

  useEffect(() => {
    AOS.init({
      offset: 200,
      duration: 600,
      easing: "ease-in-sine",
      delay: 100,
    });
  }, []);

  useEffect(() => {
    if (toast.type) setTimeout(() => setToast({ msg: "", type: "" }), 3000);
  }, [toast]);

  useEffect(() => {
    if (data.email && data.mobile_number.length === 10) sendOtpHandler();
  }, [data.mobile_number, data.email]);

  const sendOtpHandler = async () => {
    if (otpSent) return;
    if (parseInt(data.mobile_number) < 1000000000)
      return setToast({
        msg: "Please Enter Valid Mobile Number",
        type: "error",
      });
    setOtpSent(true);
    const payload = { mobile_number: data.mobile_number, email: data.email };
    apiCall
      .post(send_otp, payload)
      .then((response) => {
        console.log(response);
        if (response.status === 200) {
          setOtpSent(true);
          setToast({ msg: response.data.data, type: "success" });
        } else {
          setOtpSent(false);
          setToast({ msg: response.data.data, type: "error" });
        }
      })
      .catch((error) => {
        console.log(error);
        setToast({
          msg: error.response?.data?.message || "Something went wrong !",
          type: "error",
        });
      });
  };

  const registerHandler = async (event) => {
    event.preventDefault();
    if (!data.first_name)
      return setToast({ msg: "Please Enter First Name", type: "error" });
    else if (!data.last_name)
      return setToast({ msg: "Please Enter Last Name", type: "error" });
    else if (!data.email)
      return setToast({ msg: "Please Enter Email", type: "error" });
    else if (!data.mobile_number)
      return setToast({ msg: "Please Enter Mobile Number", type: "error" });
    else if (data.mobile_number.length !== 10)
      return setToast({
        msg: "Please Enter Valid Mobile Number",
        type: "error",
      });
    else if (!data.mobile_otp)
      return setToast({ msg: "Please Enter Mobile OTP", type: "error" });
    else if (!data.password)
      return setToast({ msg: "Please Enter Password", type: "error" });

    const payload = {
      mobile_number: data.mobile_number,
      mobile_otp: data.mobile_otp,
      email: data.email,
    };
    apiCall
      .post(verify_otp, payload)
      .then((response) => {
        console.log(response);
        if (response.status === 200) {
          apiCall
            .post(sign_up, data)
            .then((response) => {
              console.log(response);
              if (response.status === 200) {
                setToast({ msg: response.data.data, type: "success" });

                const { access_token, login_object } = response.data.data;
                set_user_data(login_object, access_token);
                localStorage.setItem("network_access_token", access_token);
                setTokenInHeaders(access_token);
                setApiToken(access_token);

                setTimeout(() => props.history.push("/dashboard"), 1000);
              } else {
                setOtpSent(false);
                setToast({ msg: response.data.data, type: "error" });
              }
            })
            .catch((error) => {
              console.log(error);
              setOtpSent(false);
              setToast({
                msg: error.response?.data?.message || "Something went wrong !",
                type: "error",
              });
            });
        } else {
          setToast({ msg: response.data.data, type: "error" });
          setOtpSent(false);
        }
      })
      .catch((error) => {
        setOtpSent(false);
        setToast({
          msg: error.response?.data?.message || "Something went wrong !",
          type: "error",
        });
      });
  };

  return (
    <div
      className=" loginContainer heroLogin"
      style={{ background: `url(${LoginPageBG})` }}
    >
      <div id="snackbar" className={toast.type ? toast.type + " show" : ""}>
        {toast.msg}
      </div>
      <div className="container">
        <div className="row">
          <div className="col-md-10 login-sec createBox">
            <h2 className="text-center">REGISTER FOR DIAGNOSTIC TESTS</h2>

            <form id="login" className="validate">
              <div className="input-box form-group">
                <input
                  type="text"
                  placeholder="First Name"
                  name="fname"
                  className="form-control CreateInput change"
                  value={data.first_name}
                  onChange={(e) =>
                    setData({ ...data, first_name: e.target.value })
                  }
                />

                {/* <small className="help-block">{usernameError}</small> */}
              </div>
              <div className="input-box form-group">
                <input
                  type="text"
                  placeholder="Last Name"
                  name="lname"
                  className="form-control CreateInput change"
                  value={data.last_name}
                  onChange={(e) =>
                    setData({ ...data, last_name: e.target.value })
                  }
                />

                {/* <small className="help-block">{usernameError}</small> */}
              </div>
              <div className="input-box form-group">
                <input
                  type="text"
                  placeholder="Email Id"
                  name="email"
                  className="form-control CreateInput change"
                  value={data.email}
                  onChange={(e) => setData({ ...data, email: e.target.value })}
                />

                {/* <small className="help-block">{usernameError}</small> */}
              </div>
              <div className="input-box form-group">
                <input
                  type="Number"
                  placeholder="Phone Number"
                  name="phoneNo"
                  className="form-control CreateInput change"
                  maxLength={10}
                  value={data.mobile_number}
                  onChange={(e) =>
                    setData({ ...data, mobile_number: e.target.value })
                  }
                />

                {/* <small className="help-block">{usernameError}</small> */}
              </div>
              <div className="input-box form-group">
                <input
                  type="Number"
                  placeholder="Mobile OTP"
                  name="verifyOtp"
                  className="form-control CreateInput change"
                  value={data.mobile_otp}
                  onChange={(e) =>
                    setData({ ...data, mobile_otp: e.target.value })
                  }
                />

                {/* <small className="help-block">{usernameError}</small> */}
              </div>
              <div className="input-box form-group ">
                <input
                  type="password"
                  placeholder="Password"
                  name="password"
                  className="form-control CreateInput change"
                  value={data.password}
                  onChange={(e) =>
                    setData({ ...data, password: e.target.value })
                  }
                />
                {/* <small className="help-block">{passwordError}</small> */}
              </div>

              <div className="submit-slide textAlignCenter">
                <input
                  type="submit"
                  value="Register"
                  className="btn btn-md btn-primary createButton1"
                  onClick={registerHandler}
                />
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
  );
}

export default CreateAccount;

// try {
//     const apiData = login({ username, password });
//     console.log(apiData, 'value sakhare')
//     apiData.then((response) => {
//         dispatch({ type: 'success' });
//         props.history.push("/dashboard");
//         console.log(response, 'value sandesh')
//     },
//         (error) => {
//             dispatch({ type: 'error' });
//             console.log(error, 'error')
//         }
//     )
// } catch (error) {
//     alert('wrong')
//     dispatch({ type: 'error' });
// }
