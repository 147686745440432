import React, { useState, useEffect } from 'react';
import './Reference.css'
import HeaderBG from '../../Assets/Reference/HeaderBG.png'
import BannerImg from '../../Assets/Reference/BannerImg.png'
import { Link } from 'react-router-dom';

import { apiCallToken } from "../../Utils/Network.js";
import { reference_book_list } from "../../Utils/ApiUrls";

import AOS from "aos";
import "aos/dist/aos.css";
function Reference() {

    useEffect(() => {
        AOS.init({
            offset: 200,
            duration: 600,
            easing: 'ease-in-sine',
            delay: 100,
        });
    }, []);

    useEffect(() => {
      getRefrenceData();
    }, []);

    const [tableData, setTableData] = useState([]);

    const getRefrenceData = () => {
      apiCallToken(`${reference_book_list}`)
        .then((response) => {
        //   console.log(response.data.data, "response for reference");

          var list_refrence_book = response.data.data ? response.data.data : [];
          setTableData(list_refrence_book);

        })
        .catch((error) => {
          console.log(error, "error fof video");
        });
    };

    return (
        <div>
            {/*<section id="hero" class="hero d-flex align-items-center heroStudentHome" style={{ background: `url(${HeaderBG})` }}>
                <div class="container">
                    <div class="row">
                        <div class="col-lg-6 d-flex flex-column justify-content-center">
                            <h1 data-aos="fade-up">Which Exam Should I Appear For ?</h1>
                            <h2 data-aos="fade-up" data-aos-delay="400">More text here for detailed information</h2>
                            <div data-aos="fade-up" data-aos-delay="600">
                                <div class="text-lg-start">
                                    <Link to="/login" class="btn-get-started scrollto d-inline-flex align-items-center justify-content-center align-self-center">
                                        <span>Get Started</span>
                                        <i class="bi bi-arrow-right"></i>
                                    </Link>
                                </div>
                            </div>
                        </div>
                        <div class="col-lg-6 hero-img" data-aos="zoom-out" data-aos-delay="200">
                            <img src={BannerImg} class="img-fluid" alt="" />
                        </div>
                    </div>
                </div>
            </section>*/}
            <section class="quiz-view" style={{ marginTop : "6%" }}>
                <div class="container">
                    <div class="row">
                        <div class="quiz-title">
                            <h2>Reference Material</h2>
                        </div>
                        <div class="row col-md-12">
                            <table class="table table-bordered">
                                <thead>
                                    <tr>
                                        <th>No.</th>
                                        <th>Name</th>
                                        <th></th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {tableData.map((itme, idx) =>
                                    (
                                        <tr>
                                            <td>{idx + 1}</td>
                                            <td>{itme.title}</td>
                                            <td><a href={itme.download_path} target="_blank" download> Click Here </a></td>
                                        </tr>

                                    ))}
                                </tbody>
                            </table>
                        </div>
                    </div>
                </div>
            </section>
        </div>
    )
}

export default Reference
