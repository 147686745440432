import React, { useEffect } from 'react';
import LoginPageImage from '../../Assets/Reference/LoginPageImage.png';
import './Login.css';
import LoginPageBG from '../../Assets/Login/LoginPageBG.png'
import login from './loginApi';
import LoginUseReducer from './LoginUseReducer';
import { apiCall, setTokenInHeaders, setApiToken } from '../../Utils/Network'
import { login_url } from '../../Utils/ApiUrls';
import { UserCredsContext } from '../../ContextApis/UserCredsContext/UserCredsContext';
import { Link, withRouter } from 'react-router-dom';
import AOS from "aos";
import "aos/dist/aos.css";
// const initialState = {
//     username: 'kanojiask62@gmail.com',
//     password: 'b@123',
//     isLoading: false,
//     error: '',
//     isLoggedIn: false,
// };

function ScholarshipCreateAccount() {

    useEffect(() => {
        AOS.init({
            offset: 200,
            duration: 600,
            easing: 'ease-in-sine',
            delay: 100,
        });
    }, []);

    return (
        <div className=" loginContainer heroLogin" style={{ background: `url(${LoginPageBG})` }}>
            <div className='container'>
                <div className="row">

                    <div className="col-md-10 login-sec createBox">
                        <h2 className="text-center">REGISTER FOR SCHOLARSHIP</h2>

                        <form id="login" className="validate">

                            <div className="input-box form-group">
                                <input
                                    type="text"
                                    placeholder="First Name"
                                    name="fname"
                                    className="form-control CreateInput change"
                                />

                                {/* <small className="help-block">{usernameError}</small> */}
                            </div>
                            <div className="input-box form-group">
                                <input
                                    type="text"
                                    placeholder="Last Name"
                                    name="lname"
                                    className="form-control CreateInput change"
                                />

                                {/* <small className="help-block">{usernameError}</small> */}
                            </div>
                            <div className="input-box form-group">
                                <input
                                    type="text"
                                    placeholder="Email Id"
                                    name="email"
                                    className="form-control CreateInput change"
                                />

                                {/* <small className="help-block">{usernameError}</small> */}
                            </div>
                            <div className="input-box form-group">
                                <input
                                    type="Number"
                                    placeholder="Phone Number"
                                    name="phoneNo"
                                    className="form-control CreateInput change"
                                />

                                {/* <small className="help-block">{usernameError}</small> */}
                            </div>
                            <div className="input-box form-group ">
                                <input
                                    type="password"
                                    placeholder="Password"
                                    name="password"
                                    className="form-control CreateInput change"
                                />
                                {/* <small className="help-block">{passwordError}</small> */}
                            </div>
                            

                            <div className="submit-slide textAlignCenter">
                                <input type="submit" value="Register" className="btn btn-md btn-primary createButton1" />
                            </div>
                        </form>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default ScholarshipCreateAccount;

 // try {
        //     const apiData = login({ username, password });
        //     console.log(apiData, 'value sakhare')
        //     apiData.then((response) => {
        //         dispatch({ type: 'success' });
        //         props.history.push("/dashboard");
        //         console.log(response, 'value sandesh')
        //     },
        //         (error) => {
        //             dispatch({ type: 'error' });
        //             console.log(error, 'error')
        //         }
        //     )
        // } catch (error) {
        //     alert('wrong')
        //     dispatch({ type: 'error' });
        // }
