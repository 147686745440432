import React, { useState } from 'react';
// import BackgroundAboutus from '../../Assets/About/BackgroundAboutus.png'
import './PrivacyPolicy.css'

function PrivacyPolicy() {
    const [show, setShow] = useState(false);
    const handletab = (e) => {
        setShow(e);
    }

    return (
        <>
            <section className="bg-img d-flex align-items-center heroAboutus" style={{height:'360px'}}></section>
            <div className='container privacyPolicy'>
                <h3 style={{ textAlign: 'center' }}>Privacy Policy</h3>
                <p>
                    Imperialonlinestudy operates the imperialonlinestudy.org website, which provides the SERVICE.
                </p>
                <p>
                    This page is used to inform website visitors regarding our policies with the collection, use, and disclosure of Personal Information if anyone decided to use our Service, the Edu loans website.
                </p>
                <p>
                    If you choose to use our Service, then you agree to the collection and use of information in relation with this policy. The Personal Information that we collect are used for providing and improving the Service. We will not use or share your information with anyone except as described in this Privacy Policy.
                </p>
                <p>
                    The terms used in this Privacy Policy have the same meanings as in our Terms and Conditions, which is accessible at eduloans.org, unless otherwise defined in this Privacy Policy.
                </p>

                <div id='info' style={{ border: '1px solid #ddd' }} >
                    <p style={{ backgroundColor: '#f5f5f5', margin: '0', padding: '10px', cursor: 'pointer' }} onClick={() => handletab(1)}>
                        <strong>Information Collection and Use</strong>
                    </p>
                    {show === 1 && <p style={{ padding: '0 10px' }}>
                        <p>
                            For a better experience while using our Service as well as to effectively respond to your inquiry, we may require you to provide us with personally identifiable information. while transacting through our Website. Some of it is mandatory where as some of it is optional:
                        </p>
                        <ul>
                            <li>Name and Sex</li>
                            <li>Date of Birth</li>
                            <li>Permanent Account Number (PAN)</li>
                            <li>Aadhaar</li>
                            <li>Telephone Number</li>
                            <li>Mobile Number</li>
                            <li>Address</li>
                            <li>Email address</li>
                            <li>Bank Account Details/ Limited Financial Information</li>
                        </ul>
                        <p>
                            At times we conduct on-line surveys to better understand the needs and profile of our visitors. When we conduct a survey, we will try (but are not obligated) to let you know how we will use the information at the time we collect information from you on the Internet.
                        </p>
                        <p>You recognize and understand that there is no compulsion on you to provide us with your Personal Information and any and all Personal Information provided by you to us is with your full consent, own volition and desire to provide such Personal Information.
                        </p>
                    </p>}
                </div>
                <div id='info' style={{ border: '1px solid #ddd' }} >
                    <p style={{ backgroundColor: '#f5f5f5', margin: '0', padding: '10px', cursor: 'pointer' }} onClick={() => handletab(2)}>
                        <strong>Data recipients, transfer, and disclosure of Personal Information</strong>
                    </p>
                    {show === 2 && <p style={{ padding: '0 10px' }}>
                        <p>We do not sell, trade, or otherwise transfer your Personal Information to outside parties. This does not include trusted third parties who assist us in conducting our business, or servicing you, so long as those parties agree to keep this information confidential. We may also release, use or disclose your Personal Information when we believe such release of information is necessary to comply with any applicable law, regulation, legal process or enforceable statutory requirement, to enforce our site policies, to protect ours or others rights, property or safety as required or permitted by law, or when we have reason to believe that disclosing the information is necessary to identify, contact or bring legal action against someone who may be causing interference with our rights or properties, whether intentionally or otherwise, or when anyone else could be harmed by such activities. In addition, all or substantially or substantially all of its assets are acquired, our customer information will most likely also be transferred in connection with such acquisition.</p>
                    </p>}
                </div>
                <div id='info' style={{ border: '1px solid #ddd' }} >
                    <p style={{ backgroundColor: '#f5f5f5', margin: '0', padding: '10px', cursor: 'pointer' }} onClick={() => handletab(3)}>
                        <strong>Log Data</strong>
                    </p>
                    {show === 3 && <p style={{ padding: '0 10px' }}>
                        <p>We want to inform you that whenever you visit our Service, we collect information that your browser sends to us that is called Log Data. This Log Data may include information such as your computer’s Internet Protocol ("IP") address, browser version, pages of our Service that you visit, the time and date of your visit, the time spent on those pages, and other statistics.</p>
                    </p>}
                </div>
                <div id='info' style={{ border: '1px solid #ddd' }} >
                    <p style={{ backgroundColor: '#f5f5f5', margin: '0', padding: '10px', cursor: 'pointer' }} onClick={() => handletab(4)}>
                        <strong>Cookies</strong>
                    </p>
                    {show === 4 && <p style={{ padding: '0 10px' }}>
                        <p>Cookies are files with small amount of data that is commonly used an anonymous unique identifier. These are sent to your browser from the website that you visit and are stored on your computer’s hard drive.</p>
                        <p>Our website uses these "cookies" to collection information and to improve our Service. You have the option to either accept or refuse these cookies, and know when a cookie is being sent to your computer. If you choose to refuse our cookies, you may not be able to use some portions of our Service.</p>
                        <p>A cookie may also be placed by our advertising server. Such cookies are used only for purposes of tracking the effectiveness of advertising served by us on our Website and no Personal Information is gathered from you by the use of these cookies, nor is this information shared with any third parties. Similarly, a cookie may be placed by our third - party advertising companies or advertisement providers or servers. These companies may use aggregated statistics about your visits to this and other websites in order to provide advertisements about goods and services that you may be interested in or any goods and services of potential interest to you. The information they collect does not include your Personal Information. The third - party advertising companies or advertisement providers may also employ technology that is used to measure the effectiveness of ads. Any such information is anonymous. They may use this anonymous information about your visits to this and other sites in order to provide advertisements about goods and services of potential interest to you. No Personal Information is collected during this process. The information is anonymous, and does not link online actions to an identifiable person. Most web browsers automatically accept cookies. Of course, by changing the options on your web browser or using certain software programs, you can control how and whether cookies will be accepted by your browser. We support your right to block any unwanted Internet activity, especially that of unscrupulous websites. However, blocking our cookies may disable certain features on the Website, and may make it impossible to purchase or use certain services available on the Website. Please note that it is possible to block cookie activity from certain websites while permitting cookies from websites you trust, like this Website.</p>
                    </p>}
                </div>
                <div id='info' style={{ border: '1px solid #ddd' }} >
                    <p style={{ backgroundColor: '#f5f5f5', margin: '0', padding: '10px', cursor: 'pointer' }} onClick={() => handletab(5)}>
                        <strong>Service Providers</strong>
                    </p>
                    {show === 5 && <p style={{ padding: '0 10px' }}>
                        <p>We may employ third-party companies and individuals due to the following reasons:</p>
                        <ul>
                            <li>To facilitate our Service</li>
                            <li>To provide the Service on our behalf</li>
                            <li>To perform Service-related services</li>
                            <li>To assist us in analyzing how our Service is used</li>
                        </ul>
                        <p>We want to inform our Service users that these third parties have access to your Personal Information. The reason is to perform the tasks assigned to them on our behalf. However, they are obligated not to disclose or use the information for any other purpose.</p>
                    </p>}
                </div>
                <div id='info' style={{ border: '1px solid #ddd' }} >
                    <p style={{ backgroundColor: '#f5f5f5', margin: '0', padding: '10px', cursor: 'pointer' }} onClick={() => handletab(6)}>
                        <strong>Security</strong>
                    </p>
                    {show === 6 && <p style={{ padding: '0 10px' }}>
                        <p>We value your trust in providing us your Personal Information, thus we are striving to use commercially acceptable means of protecting it. But remember that no method of transmission over the internet, or method of electronic storage is 100% secure and reliable, and we cannot guarantee its absolute security.</p>
                    </p>}
                </div>
                <div id='info' style={{ border: '1px solid #ddd' }} >
                    <p style={{ backgroundColor: '#f5f5f5', margin: '0', padding: '10px', cursor: 'pointer' }} onClick={() => handletab(7)}>
                        <strong>Links to Other Sites</strong>
                    </p>
                    {show === 7 && <p style={{ padding: '0 10px' }}>
                        <p>Our Service may contain links to other sites. If you click on a third-party link, you will be directed to that site. Note that these external sites are not operated by us. Therefore, we strongly advise you to review the Privacy Policy of these websites. We have no control over, and assume no responsibility for the content, privacy policies, or practices of any third-party sites or services.</p>
                    </p>}
                </div>
                <div id='info' style={{ border: '1px solid #ddd' }} >
                    <p style={{ backgroundColor: '#f5f5f5', margin: '0', padding: '10px', cursor: 'pointer' }} onClick={() => handletab(8)}>
                        <strong>Children’s Privacy</strong>
                    </p>
                    {show === 8 && <p style={{ padding: '0 10px' }}>
                        <p>Our Services do not address anyone under the age of 13. We do not knowingly collect personal identifiable information from children under 13. In the case we discover that a child under 13 has provided us with personal information, we immediately delete this from our servers. If you are a parent or guardian and you are aware that your child has provided us with personal information, please contact us so that we will be able to do necessary actions.</p>
                    </p>}
                </div>
                <div id='info' style={{ border: '1px solid #ddd' }} >
                    <p style={{ backgroundColor: '#f5f5f5', margin: '0', padding: '10px', cursor: 'pointer' }} onClick={() => handletab(9)}>
                        <strong>OPTING OUT</strong>
                    </p>
                    {show === 9 && <p style={{ padding: '0 10px' }}>
                        <p>You can ask us or third parties to stop sending you marketing messages at any time by contacting us at any time. Where you opt out of receiving these marketing messages, this will not apply to personal data provided to us as a result of a product/service purchase, warranty registration, product/service experience or other transactions.</p>
                    </p>}
                </div>
                <div id='info' style={{ border: '1px solid #ddd' }} >
                    <p style={{ backgroundColor: '#f5f5f5', margin: '0', padding: '10px', cursor: 'pointer' }} onClick={() => handletab(10)}>
                        <strong>DATA RETENTION</strong>
                    </p>
                    {show === 10 && <p style={{ padding: '0 10px' }}>
                        <p>We will only retain your personal data for as long as necessary to fulfil the purposes we collected it for, including for the purposes of satisfying any legal, accounting, or reporting requirements.</p>
                        <p>To determine the appropriate retention period for personal data, we consider the amount, nature, and sensitivity of the personal data, the potential risk of harm from unauthorized use or disclosure of your personal data, the purposes for which we process your personal data and whether we can achieve those purposes through other means, and the applicable legal requirements.</p>
                        <p>Under Indian law we will normally have to keep basic information about our customers and agency-type services (including Contact, Identity and Transaction Data) for 7 years after they cease being customers. In relation to contractual services this period may be extended to 15 years. [Thereafter, we will either destroy or return personal data to the client.</p>
                        <p>We may also anonymize your personal data (so that it can no longer be associated with you) for research or statistical purposes in which case we may use this information indefinitely without further policy to you.</p>
                    </p>}
                </div>
                <div id='info' style={{ border: '1px solid #ddd' }} >
                    <p style={{ backgroundColor: '#f5f5f5', margin: '0', padding: '10px', cursor: 'pointer' }} onClick={() => handletab(11)}>
                        <strong>Changes to This Privacy Policy</strong>
                    </p>
                    {show === 11 && <p style={{ padding: '0 10px' }}>
                        <p>We may update our Privacy Policy from time to time. Thus, we advise you to review this page periodically for any changes. We will notify you of any changes by posting the new Privacy Policy on this page. These changes are effective immediately, after they are posted on this page.</p>
                    </p>}
                </div>
                <div id='info' style={{ border: '1px solid #ddd' }} >
                    <p style={{ backgroundColor: '#f5f5f5', margin: '0', padding: '10px', cursor: 'pointer' }} onClick={() => handletab(12)}>
                        <strong>Contact Us</strong>
                    </p>
                    {show === 12 && <p style={{ padding: '0 10px' }}>
                        <p>If you have any questions or suggestions about our Privacy Policy, do not hesitate to contact us.</p>
                    </p>}
                </div>
            </div>
        </>
    )
}


export default PrivacyPolicy;