import React, { useContext, useEffect } from 'react';
import LoginPageImage from '../../Assets/Reference/LoginPageImage.png';
import './Login.css';
import LoginPageBG from '../../Assets/Login/LoginPageBG.png'
import login from './loginApi';
import LoginUseReducer from './LoginUseReducer';
import { apiCall, setTokenInHeaders, setApiToken } from '../../Utils/Network'
import { login_url } from '../../Utils/ApiUrls';
import { UserCredsContext } from '../../ContextApis/UserCredsContext/UserCredsContext';
import { Link, withRouter } from 'react-router-dom';
import AOS from "aos";
import "aos/dist/aos.css";
// const initialState = {
//     username: 'kanojiask62@gmail.com',
//     password: 'b@123',
//     isLoading: false,
//     error: '',
//     isLoggedIn: false,
// };

function ScholarshipLogin(props) {
    const { _token } = useContext(UserCredsContext);

    useEffect(() => {
        AOS.init({
            offset: 200,
            duration: 600,
            easing: 'ease-in-sine',
            delay: 100,
        });
    }, []);

    useEffect(()=>{
        if(_token){
            props.history.push('scholarship')
        }
    },[_token])

    return (
        <div className=" loginContainer heroLogin" style={{ background: `url(${LoginPageBG})` }}>
            <div className='container'>
                <div className="row">
                    <div className="col-md-5 banner-sec loginimageBox" style={{ backgroundImage: `url(${LoginPageImage})` }}>

                    </div>
                    <div className="col-md-5 login-sec loginBox">
                        <h2 className="text-center">SCHOLARSHIP LOGIN</h2>

                        <form id="login" className="validate">

                            <div className="input-box form-group">
                                <input
                                    type="text"
                                    placeholder="Email Id"
                                    name="username"
                                    className="form-control loginInput change"
                                    value="Deepika"
                                />

                                {/* <small className="help-block">{usernameError}</small> */}
                            </div>
                            <div className="input-box form-group ">
                                <input
                                    type="password"
                                    placeholder="Password"
                                    name="password"
                                    className="form-control loginInput change"
                                    value="password"
                                />
                                {/* <small className="help-block">{passwordError}</small> */}
                            </div>
                            <div className="check-slide">
                                <label className="label_check" for="checkbox-01">
                                    <input
                                        id="checkbox-01"
                                        type="checkbox"
                                    /> Remember Me
                                </label>
                                <div className="right-link">
                                    <a href="lost-password.php">Lost Password? </a>
                                    <Link className="float-right" to="/createScholarshipAccount">Register</Link>
                                </div>
                            </div>
                            <div className="submit-slide textAlignCenter">
                                <input type="submit" value="Login" className="btn btn-md btn-primary loginButton1" />
                            </div>
                        </form>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default withRouter(ScholarshipLogin);

 // try {
        //     const apiData = login({ username, password });
        //     console.log(apiData, 'value sakhare')
        //     apiData.then((response) => {
        //         dispatch({ type: 'success' });
        //         props.history.push("/dashboard");
        //         console.log(response, 'value sandesh')
        //     },
        //         (error) => {
        //             dispatch({ type: 'error' });
        //             console.log(error, 'error')
        //         }
        //     )
        // } catch (error) {
        //     alert('wrong')
        //     dispatch({ type: 'error' });
        // }_token