import React, { PureComponent } from "react";
import { PieChart, Pie, Sector, Cell, ResponsiveContainer } from "recharts";
import "./MyComponent.css";

export default class MyComponent extends PureComponent {
  // static demoUrl = 'https://codesandbox.io/s/pie-chart-with-padding-angle-7ux0o';
  constructor(props) {
    super(props);
    this.state = {
      data: [
        { name: "Group A", value: 4 },
        { name: "Group B", value: 8 },
        { name: "Group C", value: 16 },
      ],
      COLORS: ["#0066ff", "#0000ff", "#0066cc"],
    };
  }

  static getDerivedStateFromProps(props, state) {
    return { data: props.data, COLORS: props.colors };
  }

  render() {
    return (
      <div className="pie-chart-c" >
        <PieChart
          width={300}
          height={300}
          onMouseEnter={this.onPieEnter}
          className="test-pie"
        >
          <Pie
            data={this.state.data}
            cx={120}
            cy={200}
            innerRadius={60}
            outerRadius={80}
            fill="#8884d8"
            paddingAngle={5}
            dataKey="value"
          >
            {this.state.data.map((entry, index) => (
              <Cell
                key={`cell-${index}`}
                fill={this.state.COLORS[index % this.state.COLORS.length]}
              />
            ))}
          </Pie>
        </PieChart>
        {/* <div  className="content-inside-pie-c">
          <p style={{ marginBottom: "5px" }}>5/10</p>
          Attempted
        </div> */}
      </div>
    );
  }
}
