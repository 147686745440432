import React, { useState, useEffect } from 'react';
import AOS from "aos";
import "aos/dist/aos.css";
import greImg from '../../Assets/home/GRE.png';
import GMAT from '../../Assets/home/GMAT.png';
import IELTS from '../../Assets/home/IELTS.png';
import TOEFL from '../../Assets/home/TOEFL.png';
import BackgroundAboutus from '../../Assets/Price/BackgroundPrice.png';
import AboutUsimages from '../../Assets/Price/BannerPrice.png';
import Row from 'react-bootstrap/Row';
import Card from 'react-bootstrap/Card';
import Alert from 'react-bootstrap/Alert';
import Tabs from "react-bootstrap/Tabs";
import Tab from "react-bootstrap/Tabs";

import ListGroup from 'react-bootstrap/ListGroup';

import ListGroupItem from 'react-bootstrap/ListGroupItem';
import Container from 'react-bootstrap/Container';
import Col from 'react-bootstrap/Col';
import Button from 'react-bootstrap/Button';
import './price.css';
import { Link } from 'react-router-dom';
import AlternateSections from '../../Container/Homepage/AlternateSection';

function PricePage() {
    useEffect(() => {
        AOS.init({
            offset: 200,
            duration: 600,
            easing: 'ease-in-sine',
            delay: 100,
        });
        AOS.refresh();
    }, []);
    return (
        <div>
            <section id="hero" className="hero d-flex align-items-center heroPrice" style={{
                backgroundImage: `url(${BackgroundAboutus}) `
            }}>
                <div className="container">
                    <div className="row">
                        <div className="col-lg-6 d-flex flex-column justify-content-center">
                            <h1 data-aos="fade-up">Improve Your GRE Score, Guaranteed!</h1>
                            <h5 data-aos="fade-up" data-aos-delay="400" className="">
                              Prep smart at an affordable price
                            </h5>
                            <div data-aos="fade-up" data-aos-delay="600">
                                <div className="text-lg-start">
                                    <Link to="/login" className="btn-get-started scrollto d-inline-flex align-items-center justify-content-center align-self-center">
                                        <span>Get Started</span>
                                        <i className="bi bi-arrow-right"></i>
                                    </Link>
                                </div>
                            </div>
                        </div>
                        <div className="col-lg-6 hero-img" data-aos="zoom-out" data-aos-delay="200">
                            <img src={AboutUsimages} className="img-fluid" alt="" />
                        </div>
                    </div>
                </div>
            </section>
            <section id="values" className="values">
                <Container>
                    <div data-aos="fade-up">
                        <Container>
                            <Row>
                                <Col lg={4} md={4} className="colMargin">
                                    <div
                                        className="box"
                                        data-aos="fade-up"
                                    >
                                       <h4>Math, Verbal & AWA:</h4>
                                       <p>We cover all GRE topics so you are best prepared on test day</p>
                                    </div>
                                </Col>
                                <Col lg={4} md={4} className="colMargin">
                                    <div
                                        className="box"
                                        data-aos="fade-up"
                                    >
                                      <h4>Practice Test:</h4>
                                       <p>Timed, full-length practice tests from our pool of questions</p>
                                    </div>
                                </Col>
                                <Col lg={4} md={4} className="colMargin">
                                    <div
                                        className="box"
                                        data-aos="fade-up"
                                    >
                                        <h4>Expert Video Lessons:</h4>
                                       <p>Prepare for every section, on any device and 100% online</p>
                                    </div>
                                </Col>
                            </Row>

                        </Container>
                        <Container>
                            <Row>
                                <Col lg={4} md={4} className="colMargin">
                                    <div
                                        className="box"
                                        data-aos="fade-up"
                                    >
                                       <h4>Custom Study Schedules:</h4>
                                       <p>Study checklists to stay motivated, 1 week to 6 months long</p>
                                    </div>
                                </Col>
                                <Col lg={4} md={4} className="colMargin">
                                    <div
                                        className="box"
                                        data-aos="fade-up"
                                    >
                                      <h4>1400+ Practice Questions:</h4>
                                       <p>Learn from your mistakes with a video explanation to every question</p>
                                    </div>
                                </Col>
                                <Col lg={4} md={4} className="colMargin">
                                    <div
                                        className="box"
                                        data-aos="fade-up"
                                    >
                                        <h4>Accurate Score Predictor:</h4>
                                       <p>Be confident when you’re ready with our score predictor tool</p>
                                    </div>
                                </Col>
                            </Row>
                            
                        </Container>
                    </div>
                </Container>
            </section>
            <section id="values" className="values">    
                <Container>
                    <div data-aos="fade-up">
                        <header className="section-header">
                            <p>PLUS! 24/7 STUDENT SUPPORT</p>
                            <h2>Quick email and chat support from our test prep experts when you get stuck or just want some extra help.</h2>                       
                        </header>
                        <Tabs defaultActiveKey="gre" id="uncontrolled-tab-example" className="mb-3">
                          <Tab eventKey="gre" title="GRE" >
                                <Container>
                                    <Row>
                                        <Col lg={6} md={6} className="colMargin">
                                            <div
                                                className="col-md-12"
                                            >
                                                 <Card>
                                                      <Card.Header >Online Live Lectures with LMS Access</Card.Header>
                                                      <Card.Body>
                                                        <Alert variant="warning" className="text-center">
                                                         
                                                          <h2>₹15000 INR / $200 USD</h2>
                                                          
                                                        </Alert>
                                                        
                                                         <h5>Content</h5>
                                                        <ul>
                                                            <li className="align-items-center mb-2">
                                                                <b>TOTAL 72 Hrs</b> of Online Lectures .
                                                            </li>
                                                            <li className="align-items-center mb-2">
                                                                Comprehensive Access to <b>3000+ Questions</b>
                                                            </li>
                                                            <li className="align-items-center mb-2">
                                                                Access to <b>Pre recorded Classwork</b> Lectures                                                    </li>
                                                            <li className="align-items-center mb-2">
                                                                12 Inhouse <b>MOCK Test</b>
                                                            </li>
                                                            <li className="align-items-center mb-2">
                                                                Access to 12 Leading Mock Test
                                                            </li>
                                                        </ul>
                                                        <h5>Feature</h5>
                                                        
                                                        
                                                        <ul>
                                                            <li className="align-items-center mb-2">
                                                                Integrated Doubt Solving Sessions
                                                            </li>
                                                            <li className="align-items-center mb-2">
                                                                Analytical Insights on Key Areas of Improvement
                                                            </li>
                                                            <li className="align-items-center mb-2">
                                                                Pre recorded Lectures for personal progression                                                    </li>
                                                            <li className="align-items-center mb-2">
                                                                UI as Per GRE online Paper
                                                            </li>
                                                            <li className="align-items-center mb-2">
                                                                Answer Key with Question Level and Timing Analytics
                                                            </li>
                                                        </ul>
                                                        
                                                        
                                                      </Card.Body>
                                                      <Card.Footer><Button variant="Danger" className="btnSignUp">Sign Up Now</Button></Card.Footer>
                                                    </Card>
                                            </div>
                                        </Col>
                                        <Col lg={6} md={6} className="colMargin">
                                            <div
                                                className="col-md-12"
                                            >
                                                 <Card>
                                                      <Card.Header className="primaryHeader">Online Live Doubt Solving with LMS Access</Card.Header>
                                                      <Card.Body>
                                                        <Alert variant="primary" className="text-center">
                                                          
                                                          <h2>₹10000 INR / $150 USD</h2>
                                                         
                                                        </Alert>
                                                        
                                                         <h5>Content</h5>
                                                        <ul>
                                                            <li className="align-items-center mb-2">
                                                                <b>12 Hours</b> of Concept and Doubt Solving Session
                                                            </li>
                                                            <li className="align-items-center mb-2">
                                                                Comprehensive Access to <b>3000+ Questions</b>
                                                            </li>
                                                            <li className="align-items-center mb-2">
                                                                Access to <b>Pre recorded Classwork Lectures</b>                                                    </li>
                                                            <li className="align-items-center mb-2">
                                                                <b>12 Inhouse MOCK Test</b>
                                                            </li>
                                                            <li className="align-items-center mb-2">
                                                                Access to <b>12 Leading Mock Test</b>
                                                            </li>
                                                        </ul>
                                                        <h5>Feature</h5>
                                                        
                                                        
                                                        <ul>
                                                            <li className="align-items-center mb-2">
                                                                Integrated Doubt Solving Sessions
                                                            </li>
                                                            <li className="align-items-center mb-2">
                                                                Analytical Insights on Key Areas of Improvement
                                                            </li>
                                                            <li className="align-items-center mb-2">
                                                                Progressive Score Indicator                                                    
                                                            </li>
                                                            <li className="align-items-center mb-2">
                                                                UI as Per GRE online Paper 
                                                            </li>
                                                            <li className="align-items-center mb-2">
                                                                Answer Key with Question Level and Timing Analytics
                                                            </li>
                                                        </ul>
                                                        
                                                        
                                                      </Card.Body>
                                                      <Card.Footer className="primaryFooter"><Button variant="Danger" className="btnSignUp">Sign Up Now</Button></Card.Footer>
                                                    </Card>
                                            </div>
                                        </Col>
                                    </Row>
                                </Container>
                                <Container>
                                    <Row>
                                        <Col lg={6} md={6}>
                                            <div
                                                className="col-md-12"
                                            >
                                                 <Card>
                                                      <Card.Header className="successHeader">Online (Pre Recorded) with LMS Access</Card.Header>
                                                      <Card.Body>
                                                        <Alert variant="success" className="text-center">
                                                         
                                                          <h2>₹7500 INR / $100 USD</h2>
                                                          
                                                        </Alert>
                                                        
                                                         <h5>Content</h5>
                                                        <ul>
                                                            <li className="align-items-center mb-2">
                                                                Comprehensive Access to <b>3000+ Questions</b>
                                                            </li>
                                                            <li className="align-items-center mb-2">
                                                                Access to <b>Pre recorded Classwork</b> Lectures                                                    </li>
                                                            <li className="align-items-center mb-2">
                                                                12 Inhouse <b>MOCK Test</b>
                                                            </li>
                                                            <li className="align-items-center mb-2">
                                                                Access to <b>12 Leading Mock Test</b>
                                                            </li>
                                                        </ul>
                                                        <h5>Feature</h5>
                                                        
                                                        
                                                        <ul>
                                                            <li className="align-items-center mb-2">
                                                                Analytical Insights on Key Areas of Improvement
                                                            </li>
                                                            <li className="align-items-center mb-2">
                                                                Progressive Score Indicator
                                                            </li>
                                                                                                             
                                                            <li className="align-items-center mb-2">
                                                                UI as Per GRE online Paper
                                                            </li>
                                                            <li className="align-items-center mb-2">
                                                                Answer Key with Question Level and Timing Analytics
                                                            </li>
                                                        </ul>
                                                        
                                                        
                                                      </Card.Body>
                                                      <Card.Footer className="successFooter"><Button variant="" className="btnSignUp">Sign Up Now</Button></Card.Footer>
                                                    </Card>
                                            </div>
                                        </Col>
                                    </Row>
                                </Container>
                          </Tab>
                          <Tab eventKey="gmat" title="GMAT">
                            <Container>
                                    <Row>
                                        <Col lg={6} md={6}>
                                            <div
                                                className="col-md-12"
                                            >
                                                 <Card>
                                                      <Card.Header >Online Live Lectures with LMS Access</Card.Header>
                                                      <Card.Body>
                                                        <Alert variant="warning" className="text-center">
                                                         
                                                          <h2>₹20000 INR / $250 USD</h2>
                                                          
                                                        </Alert>
                                                        
                                                         <h5>Content</h5>
                                                        <ul>
                                                            <li className="align-items-center mb-2">
                                                                TOTAL <b>72 Hrs</b> of Online Lectures
                                                            </li>
                                                            <li className="align-items-center mb-2">
                                                                Comprehensive Access to <b>3000+ Questions</b>
                                                            </li>
                                                            <li className="align-items-center mb-2">
                                                                Access to <b>Pre recorded Classwork</b> Lectures                                                    
                                                            </li>
                                                            <li className="align-items-center mb-2">
                                                                12 Inhouse <b>MOCK Test</b>
                                                            </li>
                                                            <li className="align-items-center mb-2">
                                                                Access to <b>12 Leading Mock Test</b>
                                                            </li>
                                                        </ul>
                                                        <h5>Feature</h5>
                                                        
                                                        
                                                        <ul>
                                                            <li className="align-items-center mb-2">
                                                                Integrated Doubt Solving Sessions
                                                            </li>
                                                            <li className="align-items-center mb-2">
                                                                Analytical Insights on Key Areas of Improvement
                                                            </li>
                                                            <li className="align-items-center mb-2">
                                                                Pre recorded Lectures for personal progression 
                                                            </li>
                                                                                                             
                                                            <li className="align-items-center mb-2">
                                                                UI as Per GMAT online Paper
                                                            </li>
                                                            <li className="align-items-center mb-2">
                                                                Answer Key with Question Level and Timing Analytics
                                                            </li>
                                                        </ul>
                                                        
                                                        
                                                      </Card.Body>
                                                      <Card.Footer><Button variant="Danger" className="btnSignUp">Sign Up Now</Button></Card.Footer>
                                                    </Card>
                                            </div>
                                        </Col>
                                    </Row>
                                </Container>
                          </Tab>
                          <Tab eventKey="ielts" title="IELTS" >
                                <Container>
                                    <Row>
                                        <Col lg={6} md={6}>
                                            <div
                                                className="col-md-12"
                                            >
                                                 <Card>
                                                      <Card.Header >Online Live Lectures</Card.Header>
                                                      <Card.Body>
                                                        <Alert variant="warning" className="text-center">
                                                         
                                                          <h2>₹3500 INR / $50 USD</h2>
                                                          
                                                        </Alert>
                                                        
                                                         <h5>Content</h5>
                                                        <ul>
                                                            <li className="align-items-center mb-2">
                                                                TOTAL <b>36 Hrs</b> of Online Lectures
                                                            </li>
                                                            <li className="align-items-center mb-2">
                                                                Comprehensive Access to <b>1000+ Questions</b>
                                                            </li>
                                                            <li className="align-items-center mb-2">
                                                                Access to <b>Pre recorded Classwork</b> Lectures                                                    </li>
                                                            
                                                            <li className="align-items-center mb-2">
                                                                Access to <b>6 Leading Mock Test</b>
                                                            </li>
                                                        </ul>
                                                        <h5>Feature</h5>
                                                        
                                                        
                                                        <ul>
                                                            <li className="align-items-center mb-2">
                                                                Analytical Insights on Key Areas of Improvement
                                                            </li>
                                                            <li className="align-items-center mb-2">
                                                                Integrated Doubt Solving Sessions
                                                            </li>
                                                                                                             
                                                            <li className="align-items-center mb-2">
                                                                Personal Development Plan
                                                            </li>
                                                        </ul>
                                                        
                                                        
                                                      </Card.Body>
                                                      <Card.Footer><Button variant="Danger" className="btnSignUp">Sign Up Now</Button></Card.Footer>
                                                    </Card>
                                            </div>
                                        </Col>
                                    </Row>
                                </Container>
                          </Tab>
                          <Tab eventKey="toefl" title="TOEFL" >
                                <Container>
                                    <Row>
                                        <Col lg={6} md={6}>
                                            <div
                                                className="col-md-12"
                                            >
                                                 <Card>
                                                      <Card.Header >Online Live Lectures</Card.Header>
                                                      <Card.Body>
                                                        <Alert variant="warning" className="text-center">
                                                         
                                                          <h2>₹3500 INR / $50 USD</h2>
                                                          
                                                        </Alert>
                                                        
                                                         <h5>Content</h5>
                                                        <ul>
                                                            <li className="align-items-center mb-2">
                                                                TOTAL <b>36 Hrs</b> of Online Lectures
                                                            </li>
                                                            <li className="align-items-center mb-2">
                                                                Comprehensive Access to <b>1000+ Questions</b>
                                                            </li>
                                                            <li className="align-items-center mb-2">
                                                                Access to <b>Pre recorded Classwork</b> Lectures                                                    
                                                            </li>
                                                            <li className="align-items-center mb-2">
                                                                Access to <b>6 Leading Mock Test</b>
                                                            </li>
                                                        </ul>
                                                        <h5>Feature</h5>
                                                        
                                                        
                                                        <ul>
                                                            <li className="align-items-center mb-2">
                                                                Integrated Doubt Solving Sessions
                                                            </li>
                                                            <li className="align-items-center mb-2">
                                                               Analytical Insights on Key Areas of Improvement 
                                                            </li>
                                                                                                             
                                                            <li className="align-items-center mb-2">
                                                                Personal Development Plan
                                                            </li>
                                                        </ul>
                                                        
                                                        
                                                      </Card.Body>
                                                      <Card.Footer><Button variant="Danger" className="btnSignUp">Sign Up Now</Button></Card.Footer>
                                                    </Card>
                                            </div>
                                        </Col>
                                    </Row>
                                </Container>
                          </Tab>
                        </Tabs>
                        
                    </div>
                </Container>
            </section>
            <section id="values" className="values">
                <Container>
                    <div data-aos="fade-up">
                        <header className="section-header">
                            <p>Try for 7 days with no commitment</p>
                            <h2>Sign up for free trial with over 20 questions and lessons.</h2>                       
                        </header>
                        <Container>
                            <Row>
                                
                            </Row>
                        </Container>
                    </div>
                </Container>
            </section>
        </div>
    )
}

export default PricePage;
