import axios from "axios";

//export const base_url = 'http://dapi.imperialonlineprep.com';
export const base_url = "https://api.imperialonlinestudy.com";

var _access_token = localStorage.getItem("network_access_token") || "";

var headers = _access_token
  ? { "Content-Type": "application/json", "access-token": _access_token }
  : { "Content-Type": "application/json" };

export var apiCallToken = axios.create({
  baseURL: base_url,
  timeout: 300000,
  headers: headers,
  validateStatus: (status) => status < 400,
  transformRequest: [
    function (data, headers) {
      return JSON.stringify(data);
    },
  ],
});

export function setApiToken(acces_token) {
  return new Promise((resolve, reject) => {
    try {
      // console.log(acces_token,'acces_tokenacces_token')
      localStorage.setItem("network_access_token", acces_token);
      resolve("Successfully set api_call.");
    } catch {
      reject("Error to ser api_call");
    }
  });
}

export var apiCall = axios.create({
  baseURL: base_url,
  timeout: 300000,
  headers: { "Content-Type": "application/json" },
  transformRequest: [
    function (data, headers) {
      return JSON.stringify(data);
    },
  ],
});

apiCallToken.interceptors.request.use((config) => {
  const accessToken = localStorage.getItem("network_access_token");
  if (accessToken)
    config.headers = { ...config.headers, "access-token": accessToken };
  return config;
});

apiCallToken.interceptors.response.use((res) => {
  // console.log(res.status);
  if (res.status === 401) {
    localStorage.clear();
    window.open("/", "_self");
  }
  return res;
});

export var setTokenInHeaders = (_token) => {
  _access_token = _token || "";
};
