import { apiCall } from '../../Utils/Network'

export default async function login({ username, password }) {
  let data = {
    username: 'kanojiask62@gmail.com',
    password: 'b@123'
  }
  // console.log(password, 'password')
  const users = await (apiCall.post('/student/login', data)).json();
  // console.log(users, 'users in functino')
  return users
}

// let data = {
//   username,
//   password
// }
// return new Promise((resolve, reject) => {
//   alert('in')
//   login_api.post('/student/getlogin', data)
//     .then(response => {
//       console.log(response, 'response')
//       resolve('success');
//     })
//     .catch(error => {
//       reject('error');
//     })
// })



  // return new Promise((resolve, reject) => {
  //   setTimeout(() => {
  //     if (username === 'test' && password === '123') {
  //       resolve('success');
  //     } else {
  //       reject('error');
  //     }
  //   }, 1000);
  // });