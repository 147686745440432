import React, { useEffect, useState } from "react";
import { Accordion } from "react-bootstrap";
import { Plus } from "react-bootstrap-icons";
import { Dash } from "react-bootstrap-icons";
import { Link, withRouter } from "react-router-dom";
import { checkCountId } from "../../Utils/Utils";
import { apiCallToken } from "../../Utils/Network.js";
import Modal from "react-bootstrap/Modal";
import Button from "react-bootstrap/Button";
function AccordianContainer({ _data, _title, type, q_id = null, ...props }) {
  const [activeId, setActiveId] = useState("");
  const [iterateData, setIterateData] = useState({});
  const [show, setShow] = useState(false);
  const [modalData, setModalData] = useState({
    title: "",
    url: "http://commondatastorage.googleapis.com/gtv-videos-bucket/sample/BigBuckBunny.mp4",
    id:1
  });

  const openVideoModalNew = (title, v_id, v_type) => {

    const data = {
      vid: v_id,
      type: v_type,
    };

    apiCallToken
      .post(`student/video/url`, data)
      .then((response) => {
        const newData = response.data.data;
        // console.log(newData,newData.video_name,newData.keyurl,'data in local')

        setShow(true);
        setModalData({ ...modalData, title:newData.video_name, url:newData.keyurl });

      })
      .catch((error) => {
        console.log(error, "error fof video");
        alert('Something went wrong')
      });



  };

  /*const openVideoModal = (title) => {

    setShow(true);
    setModalData({ ...modalData, title });

  };*/

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);
  function toggleActive(id) {
    var new_id = id.toString();

    // console.log(new_id, "id is here");
    if (activeId === new_id) {
      setActiveId(null);
    } else {
      setActiveId(new_id);
    }
  }

  useEffect(() => {
    // Object.keys(_data).length
    if (_data) {
      setIterateData(_data);
      setActiveId("");
    }
  }, [_data]);

  // console.log(q_id, activeId, "_data");
  // console.log(iterateData,_data,'Object')
  return (
    <React.Fragment>
      {Object.keys(iterateData).length !== 0 && (
        <React.Fragment>
          {Object.entries(iterateData).map(([key, value], idx) => {
            var new_id = idx.toString();
            return (
              <Accordion defaultActiveKey={activeId} style={{ padding: "5px 5px"}}>
                <div
                  className={
                    activeId === new_id
                      ? "panel-wrap active-panel"
                      : "panel-wrap"
                  }
                >
                  <div className="panel-header">
                    <Accordion.Item
                      onClick={() => toggleActive(new_id)}
                      className="panel-toggle"
                      variant="link"
                      eventKey={new_id + ""}
                    >
                      <Accordion.Header>
                        <div>
                          {idx + 1} {key}
                        </div>
                        <div>
                          {activeId != new_id && <Plus />}
                          {activeId == new_id && <Dash />}
                        </div>
                      </Accordion.Header>
                    </Accordion.Item>
                  </div>

                  <Accordion.Collapse eventKey={new_id + ""} style={{ backgroundColor: "white" , borderRadius:"5px"}}>
                    <div className="panel-body">
                      <table className="table" style={{ width: "100%", margin: "auto" }}>
                        <tr>
                          <th>Sr. No.</th>
                          <th>Practise</th>
                          <th>Tutorial Documentary</th>
                          {type === "test" && <th>Total Attempts</th>}
                          {type === 'result' && <th>Correct Answer</th>}
                        </tr>
                        {value.map((item, idx) => {
                          var countId = checkCountId(item, q_id);
                          // console.log(item, "countId ss");
                          return (
                            <tr>
                              <td>{item.id}</td>
                              <td>{item.practice_name}</td>
                              <td>
                                {type === "video" && (
                                  <button
                                    className = "btn btn-md btn-primary"
                                    onClick={() =>
                                      openVideoModalNew(item.practice_name, item.id, 'NOHD')
                                    }
                                  >
                                    Watch Video
                                  </button>
                                )}

                                {type === "test" && item.attempts_count <= 2 && (
                                  <Link to={`test/${item.id}/${item.attempts_count + 1}`}>
                                    <button className = "btn btn-md btn-primary">Start Test (Attempt - {item.attempts_count + 1}) </button>{" "}
                                  </Link>
                                )}

                                {type === "test" && item.attempts_count >= 3 && (

                                    <button className = "btn btn-md btn-danger">Maximum Attempt Limit Over </button>

                                )}

                              </td>
                              <td>{item.attempts_count}</td>
                            </tr>
                          );
                        })}
                      </table>
                    </div>
                  </Accordion.Collapse>
                </div>
              </Accordion>
            );
          })}
        </React.Fragment>
      )}
      <Modal show={show} onHide={handleClose}>
        <Modal.Header closeButton>
          <Modal.Title>{modalData.title}</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <video controls style={{ width: "100%" }}>
            <source src={modalData.url} type="video/mp4"></source>
            <source src={modalData.url} type="video/ogg"></source>
          </video>
        </Modal.Body>
        <Modal.Footer>
          {/* <Button variant="secondary" onClick={handleClose}>
            Close
          </Button> */}
          <Button variant="primary" onClick={handleClose}>
            Close
          </Button>
        </Modal.Footer>
      </Modal>
    </React.Fragment>
  );
}

export default withRouter(AccordianContainer);

{
  /* <div className={activeId === '1' ? 'panel-wrap active-panel' : 'panel-wrap'}>
<div className="panel-header">
    <Accordion.Toggle onClick={() => toggleActive('1')} className="panel-toggle" variant="link" eventKey="1">
        <div className="accordian-head-c">
            <div>
                2  hello
            </div>
            <div>
                {activeId != 1 && <Plus />}
                {activeId == 1 && <Dash />}
            </div>
        </div>
    </Accordion.Toggle>
</div>

<Accordion.Collapse eventKey="1">
    <div className="panel-body">
        <table style={{ width: '98%', margin: 'auto' }}>
            <tr>
                <th>Sr. No.</th>
                <th>Practise</th>
                <th>Tutorial Documentary</th>
            </tr>
            <tr>
                <td>1</td>
                <td>Word Problems Warmup 1</td>
                <td><button>Watch Video</button></td>
            </tr>
        </table>
    </div>
</Accordion.Collapse>
</div> */
}