import React, { Component, createContext } from "react";
import { apiCallToken } from "../../Utils/Network";

export const UserCredsContext = createContext();
const appData = {
  user_details: {
    email: "",
    last_logged_in: "",
    login_id: "",
    mobile_number: "",
    student_id: "",
    student_name: "",
    answerArray: [],
    paid_status: "",
  },
  access_token: null,
};

const localstorage_state = localStorage.getItem("_data");

function getLocalData() {
  if (localstorage_state) {
    let local_data = JSON.parse(localstorage_state);
    return local_data;
  }
  return appData;
}

class UserCredsContextProvider extends Component {
  constructor(props) {
    super(props);
    this.state = {
      ...getLocalData(),
      answeredArray: [],
      questionChange: { prev: false, next: false },
      currentCount: 1,
      timerClock: { hour: 0, minute: 0, second: 0 },
      selectedAnswer: null,
    };
    this.setUserData = this.setUserData.bind(this);
    this.setAnsweredArray = this.setAnsweredArray.bind(this);
    this.setQuestionChange = this.setQuestionChange.bind(this);
    this.setCurrentCount = this.setCurrentCount.bind(this);
    this.setTimerClock = this.setTimerClock.bind(this);
    this.setSelectedAnswer = this.setSelectedAnswer.bind(this);
    this.setAnswerArray = this.setAnswerArray.bind(this);
  }

  componentDidMount() {
    this.validateToken();
    window.addEventListener("beforeunload", () => {
      if (localStorage.getItem("_data"))
        localStorage.setItem("_data", JSON.stringify(this.state));
    });
  }

  validateToken() {
    var _access_tkn = localStorage.getItem("network_access_token");
    if (_access_tkn)
      apiCallToken.get("student/getlogin").catch(() => this.logout());
  }

  setAnswerArray(_data) {
    const answerArray = [...this.state.answerArray, ..._data];
    this.setState({ answerArray });
  }

  setUserData(_login_data, _token) {
    const updatedState = {
      user_details: { ...this.state.user_details, ..._login_data },
      access_token: _token,
    };
    this.setState(updatedState);
    localStorage.setItem(
      "_data",
      JSON.stringify({ ...this.state, ...updatedState })
    );
  }

  setAnsweredArray(_data) {
    let appendData = [...this.state.answeredArray, _data];
    this.setState({ answeredArray: appendData });
  }

  setQuestionChange(_type, _boolean_value) {
    let appendObject = {
      ...this.state.questionChange,
      [_type]: _boolean_value,
    };
    this.setState({ questionChange: appendObject });
  }

  setCurrentCount(_data) {
    this.setState({ currentCount: _data });
  }

  setTimerClock(_hour, _minute, _second) {
    this.setState({
      timerClock: { hour: _hour, minute: _minute, second: _second },
    });
  }

  setSelectedAnswer(_id) {
    this.setState({ selectedAnswer: _id });
  }

  logout = () => {
    localStorage.clear();
    setTimeout(() => window.open("/", "_self"), 1);
  };

  // setSelectedAnswer(_id) {
  //     const selectedAnswer = [...this.state.selectedAnswer,_id];
  //     this.setState({ selectedAnswer })
  // }
  render() {
    return (
      <UserCredsContext.Provider
        value={{
          user_details: this.state.user_details,
          set_user_data: this.setUserData,
          _token: this.state.access_token,
          saveAnswer: this.setAnsweredArray,
          answeredArray: this.state.answeredArray,
          set_changeQuestion: this.setQuestionChange,
          questionChange: this.state.questionChange,
          setCurrentCount: this.setCurrentCount,
          currentCount: this.state.currentCount,
          setTimerClock: this.setTimerClock,
          setSelectedAnswer: this.setSelectedAnswer,
          selectedAnswer: this.state.selectedAnswer,
          logout: this.logout,
        }}
      >
        {this.props.children}
      </UserCredsContext.Provider>
    );
  }
}

export default UserCredsContextProvider;
