import React, { useEffect, useState, useContext } from "react";
import CheckAnswer from "../../Components/CheckAnswer/CheckAnswer";
import "./Test.css";
import { Link, useParams, withRouter } from "react-router-dom";
import StopWatch from "../../Components/StopWatch/StopWatch";
import { apiCallToken } from "../../Utils/Network.js";
import { UserCredsContext } from "../../ContextApis/UserCredsContext/UserCredsContext";
import { convertToMilliseconds } from "../../Utils/Utils";

function Test(props) {
  let { id, countId } = useParams();
  const {
    set_changeQuestion,
    setCurrentCount,
    selectedAnswer,
    setSelectedAnswer,
  } = useContext(UserCredsContext);
  const [count, setCount] = useState(1);
  const [questionArray, setQuestionArray] = useState([]);
  const [questionOptions, setQuestionOptions] = useState([]);

  const counter = (type, _count) => {
    window.scrollTo({top: 0, behavior: 'smooth'})
    if (type === "prev") {
      setCount((prevCount) => prevCount - 1);
      set_changeQuestion(type, true);
      setCurrentCount(_count - 1);
    } else if (type === "next") {
      postAnswer(type, _count);
      setCurrentCount(_count + 1);
    }
  };

  // useEffect(() => {
  //     set_changeQuestion('current_count', count)
  // }, [count])

  useEffect(() => {
    if (questionArray.length !== 0 && questionArray[count - 1].options != 0) {
      setQuestionOptions(questionArray[count - 1].options);
    }
  }, [count]);

  useEffect(() => {
    getData();
  }, []);
  const getData = () => {
    // /student/questions/practise/id/44/1
    apiCallToken
      .get(`/student/questions/practise/id/${id}/${countId}`)
      .then((response) => {
        // console.log(response.data.data.question_list, "response for test");
        setQuestionArray(response.data.data.question_list);
        if (response.data.data.length !== 0) {
          var myOptions = response.data.data.question_list[0].options;
          var insertedOptions = myOptions.map((obj) => ({
            ...obj,
            checked: false,
          }));
          setQuestionOptions(insertedOptions);
        }
      })
      .catch((error) => {
        console.log(error, "error fof video");
      });
  };

  const postAnswer = (type, _count) => {
    if (selectedAnswer == null) {
      setCount((prevCount) => prevCount + 1);
      set_changeQuestion(type, true);
      setSelectedAnswer(null);
      return;
    }
    var retreiveTime = JSON.parse(localStorage.getItem("time"));
    var total_time = convertToMilliseconds(retreiveTime);
    var integerQuestionId = parseInt(questionArray[count - 1].id);
    var integerAnswerId = parseInt(selectedAnswer);

    const data = {
      practice_id: id,
      question_id: integerQuestionId,
      ans_mapp_id: [integerAnswerId],
      time_taken: total_time,
      attempt_number: countId,
    };
    apiCallToken
      .post(`/student/answer/submit`, data)
      .then((response) => {
        setCount((prevCount) => prevCount + 1);
        set_changeQuestion(type, true);
        setSelectedAnswer(null);
      })
      .catch((error) => {
        console.log(error, "error fof video");
      });
  };
  const submitFinalAnswer = () => {
    // /review
    const data = {
      practice_id: id,
      attempt_number: countId,
    };
    localStorage.setItem("practice_id", id);
    localStorage.setItem("attempt_number", countId);
    apiCallToken
      .post(`/student/final/submit`, data)
      .then((response) => {
        const newData = JSON.stringify(response.data);
        // console.log(newData,'data in local')
        localStorage.setItem("final_data", newData);
        props.history.push('/review')
      })
      .catch((error) => {
        console.log(error, "error fof video");
        alert('Something went wrong')
      });
  };
  return (
    <div style={{ marginTop: "150px", marginBottom: "200px" }}>
      <div className="qa-c-l01">
        <div className="question-totalnum-c">
          <p>
            {" "}
            {count} out of {questionArray.length}
          </p>
        </div>
        <div className="timer-float-cla">
          <StopWatch questionCount={count} />
        </div>
        {questionArray.length && (
          <p>
            Q.{count}){" "}
            <span
              dangerouslySetInnerHTML={{
                __html: `${questionArray[count - 1].question_desc}`,
              }}
            ></span>
          </p>
        )}

        <CheckAnswer questionCount={count} displayOptions={questionOptions} _item={questionArray[count-1]}/>
      </div>
      <div className="button-container-c">
        {count !== 1 && (
          <button onClick={() => counter("prev", count)}>Previous</button>
        )}
        &nbsp;
        {count !== questionArray.length && (
          <button onClick={() => counter("next", count)}>Next</button>
        )}
        {count === questionArray.length && (
          <button onClick={submitFinalAnswer}>Submit</button>
        )}
      </div>
    </div>
  );
}

export default withRouter(Test);
