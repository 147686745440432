import React, { useEffect } from 'react';
import plearning from '../../Assets/home/Personalized_Learning.png'
import iAttention from '../../Assets/home/Individual_Attention.png'
import cctVisual from '../../Assets/home/Concept_Clarity_Through_Visual.png'
import './AlternateSection.css';
import AOS from "aos";
import "aos/dist/aos.css";
import { Check2 } from 'react-bootstrap-icons';
import * as Icon from 'react-icons/fa';
import femaleImg from '../../Assets/Profile/avatar-female.png';
import maleImg from '../../Assets/Profile/avatar-male.png';

//import Swiper from 'swiper';

import { Swiper, SwiperSlide } from "swiper/react";
// Import Swiper styles

//import "swiper/css";

import "swiper/swiper-bundle.min.css";
import "swiper/swiper-bundle.css";
//import "swiper/css/pagination"
//import "swiper/css/navigation"

// // import Swiper core and required modules
import SwiperCore, {
   Autoplay,Pagination,Navigation
 } from 'swiper';

// // install Swiper modules
SwiperCore.use([Autoplay,Pagination,Navigation]);

function AlternateSections() {
    useEffect(() => {
        AOS.init({
            offset: 200,
            duration: 600,
            easing: 'ease-in-sine',
            delay: 100,
        });
        AOS.refresh();
    }, []);
    return (
        <div>
            <section id="features" className="features">
                <div className="container" data-aos="fade-up">
                    <header className="section-header">
                    <p>OUR FORMIDABLE FEATURES</p>
                        <h2>A slew of services and a raft of features in tandem with our cardinal value render Imperial imperially inimitable in an otherwise saturated firmament.
                        </h2>
                        
                    </header>

                    <div className="row feture-tabs" data-aos="fade-up">
                        <div className="col-lg-6 padingDiv" >
                            <h3>Personalized Learning</h3>
                            <div className="tab-content">
                                <div
                                    className="tab-pane fade show active"
                                    id="tab1"
                                >
                                    <p>
                                        At Imperial, we place personalization at a very high pedestal. 

                                    </p>
                                    <ul>
                                        <li className="align-items-center mb-2">
                                            Know that your individual strengths and weaknesses will be discerned and worked upon. 
                                        </li>
                                        <li className="align-items-center mb-2">
                                            Your existence will hold itself robustly before each of our members unremittingly.
                                        </li>
                                    </ul>
                                    
                                </div>
                                
                            </div>
                        </div>
                        <div className="col-lg-6">
                            <img
                                src={plearning}
                                className="img-fluid imgHome"
                                alt=""
                            />
                        </div>
                    </div>
                    <div className="row feture-tabs" data-aos="fade-up">
                        <div className="col-lg-6">
                            <img
                                src={iAttention}
                                className="img-fluid imgHome"
                                alt=""
                            />
                        </div>
                        <div className="col-lg-6 padingDiv" >
                            <h3>Individual Attention</h3>
                            <div className="tab-content">
                                <div
                                    className="tab-pane fade show active"
                                    id="tab1"
                                >
                                    <p>
                                        At Imperial, we veritably apprehend the import of individual attention solicited by students
                                    </p>
                                    <ul>
                                        <li className=" align-items-center mb-2">
                                            We have crafted our entire pedagogy and protocol in a manner most conducive towards the fulfilment of this goal.
                                        </li>
                                        <li className=" align-items-center mb-2">
                                            Each of your individual needs based on the subtle nuances of you and your profile will be heeded and ministered to.
                                        </li>
                                    </ul>
                                    
                                </div>
                                
                            </div>
                        </div>
                    </div>
                    <div className="row feture-tabs" data-aos="fade-up">
                        
                        <div className="col-lg-6 padingDiv" >
                            <h3>Concept Clarity through Visuals</h3>
                            <div className="tab-content">
                                <div
                                    className="tab-pane fade show active"
                                    id="tab1"
                                >
                                    <p>
                                        At Imperial, we are all about painting your experience with all the tinctured hues we can get our hands on to make it cherishable for  you.
                                    </p>
                                    <ul>
                                        <li className=" align-items-center mb-2">
                                             A picture being worthier than a thousand words will never be dismissed as platitudinous, for the truth will always shine through. 
                                        </li>
                                        <li className=" align-items-center mb-2">
                                            With such a conviction, we attempt to colour and polish all learning with enough imagery for a vivid and graphic assimilation and retention of the variegated curricula.
                                        </li>
                                    </ul>
                                   
                                </div>
                                
                            </div>
                        </div>
                        <div className="col-lg-6">
                            <img
                                src={cctVisual}
                                className="img-fluid imgHome"
                                alt=""
                            />
                        </div>
                    </div>
                </div>
            </section>
            

      <section className="testimonials heroFooter ">
      <div className="container testimonialBox" data-aos="fade-up" >
            <header className="section-header">
               <p>Testimonials</p>
               <h2>Well, here are our very students vouching for us!</h2>
            </header>
        <Swiper 
        spaceBetween={20} 
        centeredSlides={true} 
        autoplay={{"delay": 2900,"disableOnInteraction": false}} 
        pagination={{ "clickable": true }} 
        navigation={true} className="mySwiper" 
        className="testimonials-slider swiper-container" >

      <div className="swiper-wrapper">
      <SwiperSlide>
        <div className="testimonial-item">
                  <div className="stars">
                    <Icon.FaStar color="#ffc107" size={30}  />
                    <Icon.FaStar color="#ffc107" size={30}  />
                    <Icon.FaStar color="#ffc107" size={30}  />
                    <Icon.FaStar color="#ffc107" size={30}  />
                    <Icon.FaStar color="#ffc107" size={30}  />
                  </div>
                  <p>
                    From the moment you walk in here, you get a sense of belonging and this feeling of homeliness doesn’t leave you. Special thanks to Rahul Sir who is ever present to solve your doubts and Ankur Sir for dumbing down verbal strategies to our level. Thank you to Sonali Ma’am for bringing in a sense of belief in us, and a big thanks to Hiren Sir for saying it would be alright no matter what marks you get. I can’t express enough gratitude to the overall effort they put together as a team and making you feel as one of their own. Cheers!
                    </p>
                  <div className="profile mt-auto">
                    <h3>EASHWAR IYER</h3>
                    <h4>SIES Graduate School of Technology</h4>
                    <p>GRE Score : 336 (Quant 170, Verbal 166, AWA 4.0) <br/>TOEFL : 112</p>
                  </div>
        </div>
      </SwiperSlide>
      <SwiperSlide>
        <div className="testimonial-item">
                  <div className="stars">
                    <Icon.FaStar color="#ffc107" size={30}  />
                    <Icon.FaStar color="#ffc107" size={30}  />
                    <Icon.FaStar color="#ffc107" size={30}  />
                    <Icon.FaStar color="#ffc107" size={30}  />
                    <Icon.FaStar color="#ffc107" size={30}  />
                  </div>
                  <p>Imperial turned out to be a very helpful choice for me. They have really knowledgeable guys driving the entire process who will stay in touch with you regularly, educate you about the process and choices if you don't have a clear idea, hands down giving your investment's worth. For me, it was the best decision I made!
                  </p>
                  <div className="profile mt-auto">
                    <h3>SIDDHANT YEOLE</h3>
                    <h4>Vivekanand Education Society's Institute of Technology (VESIT)</h4>
                    <p>GRE Score : 331 (Quants 167, Verbal 164, AWA 6.0) <br/>TOEFL : 112</p>
                  </div>
        </div>
      </SwiperSlide>
      <SwiperSlide>
        <div className="testimonial-item">
                  <div className="stars">
                    <Icon.FaStar color="#ffc107" size={30}  />
                    <Icon.FaStar color="#ffc107" size={30}  />
                    <Icon.FaStar color="#ffc107" size={30}  />
                    <Icon.FaStar color="#ffc107" size={30}  />
                    <Icon.FaStar color="#ffc107" size={30}  />
                  </div>
                  <p>Imperial has played a vital role in my success to score 328 in GRE. The resources provided by them like the study materials and mock tests helped me immensely during my preparation. Ankur Sir was very approachable for clearing out my doubts and helped me understand the concepts better.
                  </p>
                  <div className="profile mt-auto">
                    <h3>CHHAVI KANDOI</h3>
                    <h4>Mithibai College, Mumbai</h4>
                    <p>GRE Score : 328 (Quants 168, Verbal 160, AWA 3.5) <br/>TOEFL : 108</p>
                  </div>
        </div>
      </SwiperSlide>
      <SwiperSlide>
        <div className="testimonial-item">
                  <div className="stars">
                    <Icon.FaStar color="#ffc107" size={30}  />
                    <Icon.FaStar color="#ffc107" size={30}  />
                    <Icon.FaStar color="#ffc107" size={30}  />
                    <Icon.FaStar color="#ffc107" size={30}  />
                    <Icon.FaStar color="#ffc107" size={30}  />
                  </div>
                  <p>You have given me a chance to achieve my dreams and live them. It seemed a little difficult but you made it possible for me. With all your support and guidance I reached so far. I promise to the Team of Imperial I will always try to make you proud of me where ever I am. You guys were a great help not only for my process but gave me support and confidence professioally as well as personally. Thank You Imperial Team!
                  </p>
                  <div className="profile mt-auto">
                    <h3>PRANOTI KUMBHARE</h3>
                    <h4>Ms Network Systems Swinburne University of Technology</h4>
                    <p>GRE Score : 312 (Quants 161, Verbal 151, AWA 3.0) <br/>TOEFL : 106</p>
                  </div>
        </div>
      </SwiperSlide>
      <SwiperSlide>
        <div className="testimonial-item">
                  <div className="stars">
                    <Icon.FaStar color="#ffc107" size={30}  />
                    <Icon.FaStar color="#ffc107" size={30}  />
                    <Icon.FaStar color="#ffc107" size={30}  />
                    <Icon.FaStar color="#ffc107" size={30}  />
                    <Icon.FaStar color="#ffc107" size={30}  />
                  </div>
                  <p>I want to thank Ankur Sir and Joydeep Sir who were extremely helpful in my preparation of GRE. Their tips aided me to achieve the score that I got. I underperformed in verbal and the score could have been much better. As for quants, I had no problems whatsoever. Ankur Sir helped me with all the doubts and the shortcuts he suggested made it even easier.
                  </p>
                  <div className="profile mt-auto">
                    <h3>AYUSH AGARWAL</h3>
                    <h4>Thakur College of Engineering and Technology</h4>
                    <p>GRE Score : 314 (Quants 170, Verbal 144, AWA 4.5)</p>
                  </div>
        </div>
      </SwiperSlide>
      </div>

        </Swiper>
        </div>
      </section>

        </div>
    )
}

export default AlternateSections;