import React, { useState, useEffect } from "react";
import MyComponent from "../../Components/PieChart/MyComponent";
import { apiCallToken } from "../../Utils/Network.js";
import "./ReviewAnswer.css";
import {
  insertAnswers,
  getPercentage,
  getTotalTime,
  insertAnswerObject,
  convertAnswer,
  checkCorrectAnswer,
} from "../../Utils/Utils";
import { useParams } from "react-router";
import { splitOptions } from "../../Utils/Utils";
import Tabs from "react-bootstrap/Tabs";
import Tab from "react-bootstrap/Tabs";
function ReviewAnswer() {
  const { p_id, c_id } = useParams();
  const [practiceInfo, setPracticeInfo] = useState({});
  const [tabBoolean, setTabBoolean] = useState({
    first: false,
    second: false,
    third: false,
  });
  const [tabKey, setTabKey] = useState(1);
  useEffect(() => {
    setTabKey(c_id);
    if (c_id == 1) setTabBoolean({ first: true, second: false, third: false });
    if (c_id == 2) {
      setTabBoolean({ first: true, second: true, third: false });
    }
    if (c_id == 3) {
      setTabBoolean({ first: true, second: true, third: true });
    }
  }, [c_id]);
  const [questionList, setQuestionList] = useState([]);
  const [answerList, setAnswerList] = useState([]);
  const [resultData, setResultData] = useState({ status: false });
  const [correctTime, setCorrectTime] = useState("");
  const [wrongTime, setWrongTime] = useState("");
  const [dataObject, setDataObject] = useState({
    avg_time_taken_correct_ans: null,
    avg_time_taken_wrong_ans: null,
    blank_ans_count: null,
    correct_ans_count: null,
    wrong_ans_count: null,
  });
  useEffect(() => {
    const data = JSON.parse(localStorage.getItem("final_data"));
    if (data) {
      const {
        question_list,
        question_ans_list,
        avg_time_taken_correct_ans,
        avg_time_taken_wrong_ans,
        blank_ans_count,
        correct_ans_count,
        wrong_ans_count,
      } = data.data;
      setDataObject({
        avg_time_taken_correct_ans,
        avg_time_taken_wrong_ans,
        blank_ans_count,
        correct_ans_count,
        wrong_ans_count,
      });
      const correct_ans_time = getTotalTime(avg_time_taken_correct_ans);
      const wrong_ans_time = getTotalTime(avg_time_taken_wrong_ans);
      setCorrectTime(correct_ans_time);
      setWrongTime(wrong_ans_time);
      const final_list = insertAnswers(question_list, question_ans_list);
      var myArray = insertAnswerObject(question_list, question_ans_list);
      setQuestionList(myArray);
      setAnswerList(question_ans_list);
      var results = getPercentage(correct_ans_count, question_list.length);
      if (Math.floor(results) >= 70) {
        setResultData({ status: true });
      } else {
        setResultData({ status: false });
      }
    }
  }, []);
  useEffect(() => {
    getData(c_id);
  }, []);
  const getData = (cid) => {
    const data = {
      attempt_number: cid,
      practice_id: p_id,
    };
    // apiCallToken
    //   .post(`/student/questions/practise/result/allattempts`, data)
    //   .then((response) => {});
    apiCallToken
      .post(`/student/questions/practise/result`, data)
      .then((response) => {
        const {
          questions,
          practice_info,
          question_list,
          result_obj,
          total_attempt_count,
        } = response.data.data;
        setTabBoolean({
          first: true,
          second: total_attempt_count > 1,
          third: total_attempt_count > 2,
        });
        if (practice_info?.length) setPracticeInfo(practice_info[0]);
        setAnswerList(question_list);
        var myArray = insertAnswerObject(questions, question_list);
        setQuestionList(myArray);
        if (!result_obj) return;
        const {
          avg_time_taken_correct_ans,
          avg_time_taken_wrong_ans,
          blank_ans,
          correct_ans,
          wrong_ans,
        } = result_obj[0];
        var results = getPercentage(correct_ans, question_list);
        if (Math.floor(results) >= 70) setResultData({ status: true });
        else setResultData({ status: false });
        setDataObject({
          avg_time_taken_correct_ans,
          avg_time_taken_wrong_ans,
          blank_ans_count: parseInt(blank_ans || 0),
          correct_ans_count: parseInt(correct_ans || 0),
          wrong_ans_count: parseInt(wrong_ans || 0),
        });
        const correct_ans_time = getTotalTime(avg_time_taken_correct_ans);
        const wrong_ans_time = getTotalTime(avg_time_taken_wrong_ans);
        setCorrectTime(correct_ans_time);
        setWrongTime(wrong_ans_time);
        // const final_list = insertAnswers(question_list, question_ans_list);
        // setAnswerList(question_ans_list);
      })
      .catch((error) => {});
  };
  const [displayData, setDisplayData] = useState([
    {
      question_title:
        "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.",
      correct: true,
      options: [
        {
          title: "unrefined",
          attempted: true,
        },
        {
          title: "Modest",
          attempted: false,
        },
        {
          title: "Uncommon",
          attempted: false,
        },
        {
          title: "Chic",
          attempted: false,
        },
        {
          title: "Elegant",
          attempted: false,
        },
      ],
    },
    {
      question_title:
        "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.",
      correct: false,
      options: [
        {
          title: "unrefined",
          attempted: false,
        },
        {
          title: "Modest",
          attempted: false,
        },
        {
          title: "Uncommon",
          attempted: true,
        },
        {
          title: "Chic",
          attempted: false,
        },
        {
          title: "Elegant",
          attempted: false,
        },
      ],
    },
  ]);
  const handleSelect = (key) => {
    getData(key);
    setTabKey(key);
  };
  const testResult = () => {
    return (
      <React.Fragment>
        <div className="review-c-he-01">
          <div style={{ width: "35%" }}>
            <p style={{ color: "#f34325" }}>
              You answered {dataObject.correct_ans_count} correct out of{" "}
              {questionList.length} Question
            </p>
            <p style={{ color: "#f34325" }}>
              Average Time Taken for Correct Answer is {correctTime}
            </p>
            <p style={{ color: "#f34325" }}>
              Average Time Taken for Wrong Answer is {wrongTime}
            </p>
            <p style={{ fontSize: 18, margin: "28px 0" }}>
              {resultData.status ? (
                <b>Congratulations you have passed</b>
              ) : (
                <b>Unfortunately you did not pass</b>
              )}
              {/* <br />
              <span>
                Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do
                eiusmod tempor incididunt ut labore et dolore magna aliqua.{" "}
              </span> */}
            </p>
          </div>
          <div className="pie-chart-c" style={{ width: "45%" }}>
            <MyComponent
              data={[
                { name: "Right", value: dataObject.correct_ans_count },
                { name: "Wrong", value: dataObject.wrong_ans_count },
                { name: "No attempt", value: dataObject.blank_ans_count },
              ]}
              colors={["#4154f1", "#f34325", "#d0d0d0"]}
            />
            <div className="content-inside-pie-c">
              <p style={{ margin: "0", fontSize: 15 }}>
                {parseInt(dataObject.correct_ans_count) +
                  parseInt(dataObject.wrong_ans_count)}
                /{questionList.length + " "}
                Attempted
              </p>
            </div>
          </div>
        </div>
        <div className="question-answer-display">
          <div className="qa-card">
            {/* {displayData.map((item, idx) => {
                      return (
                      <div className="qa-card-ind">
                      <p>Question {idx + 1}</p>
                      <hr />
                      <p>{item.question_title}</p>
                      <p
                      className={`${
                      item.correct ? "answer-is-right" : "answer-is-wrong"
                      }`}
                      >
                      That's {item.correct ? "Correct" : "Wrong"} answer
                      </p>
                      {item.options.map((nxItem, nxIdx) => {
                      return (
                      <p>
                      {item.correct && (
                      <React.Fragment>
                      <span
                      className={`${
                      nxItem.attempted
                      ? "alphabet-uppercase-r-t"
                      : "alphabet-uppercase-r-f"
                      } alphabet-uppercase`}
                      style={{ textTransform: "uppercase" }}
                      >
                      {alphabet[nxIdx]}
                      </span>{" "}
                      {nxItem.title}
                      </React.Fragment>
                      )}
                      {!item.correct && (
                      <React.Fragment>
                      <span
                      className={`${
                      nxItem.attempted
                      ? "alphabet-uppercase-w-t"
                      : "alphabet-uppercase-w-f"
                      } alphabet-uppercase`}
                      style={{ textTransform: "uppercase" }}
                      >
                      {alphabet[nxIdx]}
                      </span>{" "}
                      {nxItem.title}
                      </React.Fragment>
                      )}
                      </p>
                      );
                      })}
                      </div>
                      );
                      })} */}
          </div>
          <div className="qa-card">
            {questionList.length !== 0 &&
              questionList.map((item, idx) => {
                // if (JSON.parse(item.answer_ids))
                //   answerStatus = checkCorrectAnswer(
                //     item.answer_ids,
                //     item.correct_option
                //   );
                var getSplitArray = [];
                getSplitArray = splitOptions(questionList, item.options, idx);
                return (
                  <div className="qa-card-ind">
                    <div className="col-md-12" style={{ display: "flex" }}>
                      <div className="col-md-3">
                        <p>Question {idx + 1} </p>
                      </div>
                      <div className="col-md-4">
                        <p>Level : {item.level_id || 0}</p>
                      </div>
                      <div style={{ textAlign: "right" }} className="col-md-5">
                        <p style={{ marginBottom: 5 }}>
                          Time Taken : {answerList[idx].time_taken || 0} seconds
                        </p>
                        {answerList[idx].student_previous_ans_time && (
                          <p style={{ margin: 0 }}>
                            Previous Time Taken :{" "}
                            {answerList[idx].student_previous_ans_time +
                              " seconds"}
                          </p>
                        )}
                      </div>
                    </div>
                    <hr />
                    <p
                      dangerouslySetInnerHTML={{
                        __html: `${item.question_desc}`,
                      }}
                    ></p>
                    <div
                      style={{
                        display: "flex",
                        justifyContent: "space-between",
                        marginBottom: 10,
                        padding: 20,
                      }}
                      className={`${
                        answerList[idx].ans_status === "WRONG"
                          ? "answer-is-wrong"
                          : answerList[idx].ans_status === "CORRECT"
                          ? "answer-is-right"
                          : "not-answered"
                      }`}
                    >
                      <span>
                        {!answerList[idx].ans_status && "Not Answered"}
                        {answerList[idx].ans_status === "CORRECT" &&
                          "Correct Answer"}
                        {answerList[idx].ans_status === "WRONG" &&
                          "Wrong Answer"}{" "}
                      </span>
                      {answerList[idx].student_previous_ans_array?.length >
                        0 && (
                        <p style={{ margin: 0 }}>
                          Previous Answer:{" "}
                          {getSplitArray[0]
                            ?.filter((o) =>
                              answerList[
                                idx
                              ].student_previous_ans_array?.includes(
                                parseInt(o.option_mapping)
                              )
                            )
                            .map((o, i) => {
                              const parser = new DOMParser();
                              const value = parser.parseFromString(
                                o.options_val,
                                "text/html"
                              ).firstChild.textContent;
                              return (
                                <span
                                  key={i}
                                  style={{ margin: 0, textAlign: "right" }}
                                >
                                  {i === 0 ? value : " , " + value}
                                </span>
                              );
                            })}
                        </p>
                      )}
                    </div>
                    {/* <p
                        className={`${
                        correct == 1
                        ? "answer-is-right"
                        : correct == 0
                        ? "answer-is-wrong"
                        : "not-answered"
                        }`}
                        >
                        That's{" "}
                        {correct == 1
                        ? "Correct answer"
                        : correct == 0
                        ? "Wrong answer"
                        : "Not Answered"}{" "}
                        </p> */}
                    <div className="checklist-answer-c">
                      {getSplitArray.map((_item, _idx) => {
                        const { wrong_array, correct_array } =
                          convertAnswer(item);
                        return (
                          <div
                            style={{
                              width: `${100 / getSplitArray.length}%`,
                            }}
                          >
                            {_item.map((newItem, newIdx) => {
                              let answer_type = "answer-not-attempted";
                              let option_id = Number(newItem.option_mapping);
                              if (
                                correct_array !== null &&
                                correct_array.includes(option_id)
                              ) {
                                answer_type = "correct-answer";
                              } else if (
                                wrong_array !== null &&
                                wrong_array.includes(option_id)
                              ) {
                                answer_type = "wrong-answer";
                              }
                              // var myvalue = item.answer_ids.includes(
                              //   Number(newItem.option_mapping)
                              // );
                              var myvalue = 11;
                              return (
                                <div className="indi-options-c">
                                  <div className="question-answer-ff">
                                    <div className="check-ans-cf">
                                      {answer_type == "wrong-answer" &&
                                        myvalue &&
                                        "W"}
                                    </div>
                                    <span
                                      className={`${answer_type} alphabet-uppercase`}
                                      style={{ textTransform: "uppercase" }}
                                    >
                                      {alphabet[newIdx]}
                                    </span>
                                    <div className="check-ans-cf">
                                      {answer_type == "correct-answer" &&
                                        myvalue &&
                                        "R"}
                                    </div>
                                  </div>
                                  <span
                                    dangerouslySetInnerHTML={{
                                      __html: `${newItem.options_val}`,
                                    }}
                                  ></span>
                                </div>
                              );
                            })}
                          </div>
                        );
                      })}
                    </div>
                  </div>
                );
              })}
          </div>
        </div>
      </React.Fragment>
    );
  };
  return (
    <div>
      <div className="review-container" style={{ marginTop: "100px" }}>
        <h2>{practiceInfo?.practice_name}</h2>
        <Tabs activeKey={tabKey} onSelect={handleSelect}>
          <Tab eventKey={1} title="First Attempt" disabled={!tabBoolean.first}>
            {tabKey == 1 && testResult()}
          </Tab>
          <Tab
            eventKey={2}
            title="Second Attempt"
            disabled={!tabBoolean.second}
          >
            {tabKey == 2 && testResult()}
          </Tab>
          <Tab eventKey={3} title="Third Attempt" disabled={!tabBoolean.third}>
            {tabKey == 3 && testResult()}
          </Tab>
        </Tabs>
      </div>
    </div>
  );
}
export default ReviewAnswer;
const alphabet = [
  "a",
  "b",
  "c",
  "d",
  "e",
  "f",
  "g",
  "h",
  "i",
  "j",
  "k",
  "l",
  "m",
  "n",
  "o",
  "p",
  "q",
  "r",
  "s",
  "t",
  "u",
  "v",
  "w",
  "x",
  "y",
  "z",
];
