import React, { useState, useEffect } from 'react';
import LoginPageImage from '../../Assets/Contact/ContactUs.png';
import './Login.css';
import './contactUs.css';
import LoginPageBG from '../../Assets/Contact/ContactusBG.png'
import { Link, withRouter } from 'react-router-dom';
import AOS from "aos";
import "aos/dist/aos.css";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faMapMarkerAlt } from '@fortawesome/free-solid-svg-icons';
import { faPhoneAlt } from '@fortawesome/free-solid-svg-icons';

import { faPaperPlane } from '@fortawesome/free-solid-svg-icons';

import { faGlobe } from '@fortawesome/free-solid-svg-icons';
import * as Icon from 'react-bootstrap-icons';

function Contact() {
     useEffect(() => {
        AOS.init({
            offset: 200,
            duration: 600,
            easing: 'ease-in-sine',
            delay: 100,
        });
        AOS.refresh();
    }, []);
    return (
        <div>
        <div className=" loginContainer heroContact" style={{ background: `url(${LoginPageBG})` }}>
            <div className='container'>
                <div className="row">
                    <div className="col-md-5 banner-sec loginimageBox" style={{ backgroundImage: `url(${LoginPageImage})` }}>

                    </div>
                    <div className="col-md-5 login-sec contactBox">
                        <h2 className="text-center">Contact Us To</h2>
                        <h5 className="text-center">AVAIL A FREE GRE DEMO AT ANY OF OUR BRANCHES</h5>
                       <div className="text-center text-lg-center">
                                    <Link
                                        to='/login' className="btn-get-started scrollto d-inline-flex align-items-center justify-content-center align-self-center"
                                    >
                                        <span>Get Started</span>
                                        <Icon.ArrowRight />
                                    </Link>
                                </div>

                    </div>
                </div>
            </div>
        </div>
        <section>
            <div className="row justify-content-center">
                <div className="col-md-12">
                  <div className="wrapperc">
                    <div className="row no-gutters">
                      <div className="col-lg-8 col-md-7 order-md-last d-flex align-items-stretch">
                        <div className="contact-wrap w-100 p-md-5 p-4">
                          <h3 className="mb-4">Get in touch</h3>
                          <div id="form-message-warning" className="mb-4" />
                          <div id="form-message-success" className="mb-4">
                            Your message was sent, thank you!
                          </div>
                          <form method="POST" id="contactForm" name="contactForm" className="contactForm" noValidate="novalidate">
                            <div className="row">
                              <div className="col-md-6 contactForm">
                                <div className="form-group">
                                  <label className="label" htmlFor="name">Full Name</label>
                                  <input type="text" className="form-control" name="name" id="name" placeholder="Name" />
                                </div>
                              </div>
                              <div className="col-md-6 contactForm">
                                <div className="form-group">
                                  <label className="label" htmlFor="email">Email Address</label>
                                  <input type="email" className="form-control" name="email" id="email" placeholder="Email" />
                                </div>
                              </div>
                              <div className="col-md-12 contactForm">
                                <div className="form-group">
                                  <label className="label" htmlFor="subject">Subject</label>
                                  <input type="text" className="form-control" name="subject" id="subject" placeholder="Subject" />
                                </div>
                              </div>
                              <div className="col-md-12 contactForm">
                                <div className="form-group">
                                  <label className="label" htmlFor="#">Message</label>
                                  <textarea name="message" className="form-control" id="message" cols={30} rows={4} placeholder="Message" defaultValue={""} />
                                </div>
                              </div>
                              <div className="col-md-12 contactForm">
                                <div className="form-group">
                                  <input type="submit" defaultValue="Send Message" className="btn btn-primary" />
                                  <div className="submitting" />
                                </div>
                              </div>
                            </div>
                          </form>
                        </div>
                      </div>
                      <div className="col-lg-4 col-md-5 d-flex align-items-stretch">
                        <div className="info-wrap bg-primary w-100 p-md-5 p-4">
                          <h3>Let's get in touch</h3>
                          <p className="mb-4">We're open for any suggestion or just to have a chat</p>
                          <div className="dbox w-100 d-flex align-items-start">
                            <div className="icon d-flex align-items-center justify-content-center">
                              <span className="fa fa-map-marker" /><FontAwesomeIcon icon={faMapMarkerAlt} />
                            </div>
                            <div className="text pl-3">
                              <p><span>Address:</span><br/><b>US - Imperial Platforms Inc : </b> 580 California St, Suite 1200, San Francisco, CA, US State, 94104 <br/><br/> <b>India - Imperia Platforms Pvt Ltd  :</b>Office no 32, 2nd Floor, Dheeraj Heritage, Santacruz (W), Mumbai 400054</p>
                            </div>
                          </div>
                          <div className="dbox w-100 d-flex align-items-center">
                            <div className="icon d-flex align-items-center justify-content-center">
                              <span className="fa fa-phone" /><FontAwesomeIcon icon={faPhoneAlt} />
                            </div>
                            <div className="text pl-3">
                              <p><span>Phone:</span> <a href=""><br/><b>US :</b> +1 347 328 8189 / +1 347 5785311 <br/><br/> <b>India:</b> +91 8291914507</a></p>
                            </div>
                          </div>
                          <div className="dbox w-100 d-flex align-items-center">
                            <div className="icon d-flex align-items-center justify-content-center">
                              <span className="fa fa-paper-plane" /><FontAwesomeIcon icon={faPaperPlane} />
                            </div>
                            <div className="text pl-3">
                              <p><span>Email:</span> <a href="mailto:info@yoursite.com">info@yoursite.com</a></p>
                            </div>
                          </div>
                          <div className="dbox w-100 d-flex align-items-center">
                            <div className="icon d-flex align-items-center justify-content-center">
                              <span className="fa fa-globe" /><FontAwesomeIcon icon={faGlobe} />
                            </div>
                            <div className="text pl-3">
                              <p><span>Website</span> <a href="https://www.imperial-overseas.com/">www.imperial-overseas.com</a></p>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
            </div>
        </section>
        </div>
    )
}

export default Contact;

