import React, { useEffect, useState, useContext } from "react";
// import CheckAnswer from "../../Components/CheckAnswer/CheckAnswer";
import "./Testn.css";
import { Link, useParams, withRouter } from "react-router-dom";
import StopWatch from "../../Components/StopWatch/StopWatch";
import { apiCallToken } from "../../Utils/Network.js";
import { UserCredsContext } from "../../ContextApis/UserCredsContext/UserCredsContext";
import { convertToMilliseconds } from "../../Utils/Utils";
// import AccordianContainer from "../../Components/AccordianContainer/AccordianContainer";
//import AccordianContainerNew from "../../Components/AccordianContainerNew/AccordianContainerNew";
import InsideAccordian from "../../Components/InsideContainer/InsideAccordian";

const tempOptions = [
  [11, 12, 13, 14, 15, 16, 17, 18, 19],
  [21, 22, 23, 24, 25, 26, 27, 28, 29],
  [31, 32, 33, 34, 35, 36, 37, 38, 39],
];

const useUnload = (fn) => {
  const cb = React.useRef(fn);

  React.useEffect(() => {
    const onUnload = cb.current;
    window.addEventListener("beforeunload", onUnload);
    return () => {
      window.removeEventListener("beforeunload", onUnload);
    };
  }, [cb]);
};

function Testn(props) {
  let { id, countId } = useParams();
  const {
    set_changeQuestion,
    setCurrentCount,
    selectedAnswer,
    setSelectedAnswer,
    currentCount,
    // answerArray,
    // setAnswerArray,
  } = useContext(UserCredsContext);
  const [count, setCount] = useState(1);
  const [practiceInfo, setPracticeInfo] = useState({});
  const [questionArray, setQuestionArray] = useState([]);
  // const [questionOptions, setQuestionOptions] = useState([]);
  const [optionsArray, setOptionsArray] = useState([]);
  // const [arrayList, setArrayList] = useState([]);
  // const [storeId, setStoreId] = useState([]);
  const [selectedAnswerArray, setSelectedAnswerArray] = useState([]);
  // const [answeredList, setAnsweredList] = useState([]);
  const [videoList, setVideoList] = useState([]);
  const [answered, setAnswered] = useState(false);
  const [toast, setToast] = useState({ msg: "", type: "" });
  /*const [accordianData, setAccordianData] = useState({
    Video: [
      {
        id: "203",
        practice_name: "Warmup Geometry",
        attempts_count: 0,
      },
    ],
  });*/
  // const [accordianTitle, setAccordianTitle] = useState("Demo");

  useUnload((e) => {
    e.preventDefault();
    const exit = window.confirm("Are you sure you want to leave?");
    if (exit) window.close();
  });

  const counter = (type, _count) => {
    window.scrollTo({ top: 0, behavior: "smooth" });
    if (type === "prev") {
      setCount((prevCount) => prevCount - 1);
      set_changeQuestion(type, true);
      setCurrentCount(_count - 1);
    } else if (type === "next") {
      if (!selectedAnswerArray[count - 1])
        setSelectedAnswerArray([...selectedAnswerArray, []]);
      postAnswer(type, _count);
      if (questionArray.length !== currentCount) {
        setCurrentCount(_count + 1);
      }
    }
  };

  // useEffect(() => {
  //     set_changeQuestion('current_count', count)
  // }, [count])

  useEffect(() => {
    if (toast.type) setTimeout(() => setToast({ msg: "", type: "" }), 3000);
  }, [toast]);

  useEffect(() => {
    if (count <= questionArray.length) {
      if (questionArray.length !== 0 && questionArray[count - 1]?.options) {
        // setQuestionOptions(questionArray[count - 1].options);
        setOptionsCheck(
          questionArray,
          questionArray[count - 1].options,
          count - 1
        );
      }
    }
  }, [count]);

  useEffect(() => {
    window.scrollTo({ top: 0, behavior: "smooth" });
    getData();
  }, []);
  const getData = () => {
    // /student/questions/practise/id/44/1
    apiCallToken
      .get(`/student/questions/practise/id/${id}/${countId}`)
      .then((response) => {
        const { question_list, user_answer_list, video_list, practice_info } =
          response.data.data;
        // console.log(question_list, "question_list");
        var answer_list = [];
        // var questionLists = question_list;
        for (var i = 0; i <= question_list.length - 1; i++) {
          let insert_obj = question_list[i].options;
          for (var j = 0; j <= question_list[i].options.length - 1; j++) {
            insert_obj[j].checked = false;
          }
          answer_list.push(insert_obj);
        }
        setQuestionArray(question_list);
        if (question_list.length) {
          var insertedOptions = question_list[0].options;
          if (user_answer_list?.length) {
            setAnswered(true);
            insertedOptions = insertedOptions.map((item) => {
              const answered = user_answer_list.some(
                (ua) => ua.question_id === item.id
              );
              item.checked = answered;
              return item;
            });
            var answersss = question_list.map((q) => {
              var answerrr = user_answer_list.find(
                (ua) => ua.question_id === q.id
              );
              if (answerrr) return answerrr.answer_ids;
              return [];
            });
            setSelectedAnswerArray(answersss);
          }
          setOptionsCheck(question_list, insertedOptions, 0);
        }
        setPracticeInfo(practice_info.length ? practice_info[0] : {});
        if (video_list.length) {
          var video_practice_list = {
            "Video List": video_list,
          };
          // console.log(video_practice_list,"NEW code");
          setVideoList(video_practice_list);
        }
      })
      .catch((error) => {
        console.log(error, "error fof video");
      });
  };

  const postAnswer = (type, _count) => {
    // if (selectedAnswer == null) {
    //   setCount((prevCount) => prevCount + 1);
    //   set_changeQuestion(type, true);
    //   setSelectedAnswer(null);
    //   return;
    // }

    var answerIs = [];
    var result;
    for (var i = 0; i <= optionsArray.length - 1; i++) {
      result = optionsArray[i].filter((obj) => obj.checked);
      if (result.length) {
        // answerIs = result.map((r) => r.option_mapping);
        answerIs = [...answerIs, ...result.map((r) => r.option_mapping)];
        // answerIs.push(result[0].option_mapping);
      }
    }
    answerIs.sort((a, b) => parseFloat(a) - parseFloat(b));
    answerIs = answerIs.map(Number);
    // console.log(answerIs, "answerIs");
    // if (selectedAnswerArray[count - 1]) {
    //   let answArr = selectedAnswerArray;
    //   answArr.splice(count - 1, 1, answerIs);
    //   setSelectedAnswerArray(answArr);
    // } else {
    //   setSelectedAnswerArray([...selectedAnswerArray, answerIs]);
    // }
    if (!answerIs.length) {
      // console.log(count, currentCount, questionArray.length);
      if (count === questionArray.length) {
        return optionsArray.length
          ? submitFinalAnswer(answered)
          : props.history.push("/question");
      } else {
        setCount((prevCount) => prevCount + 1);
        set_changeQuestion(type, true);
        setSelectedAnswer(null);
        return;
      }
    }
    var retreiveTime = JSON.parse(localStorage.getItem("time"));
    var total_time = convertToMilliseconds(retreiveTime);
    var integerQuestionId = parseInt(questionArray[count - 1].id);
    var integerAnswerId = parseInt(selectedAnswer);

    const data = {
      practice_id: id,
      question_id: integerQuestionId,
      ans_mapp_id: answerIs,
      time_taken: total_time / 1000,
      attempt_number: countId,
    };
    // console.log(answerIs, "submit answer");
    apiCallToken
      .post(`/student/answer/submit`, data)
      .then((response) => {
        setAnswered(true);
        if (currentCount >= questionArray.length) submitFinalAnswer(true);
        else {
          setCount((prevCount) => prevCount + 1);
          set_changeQuestion(type, true);
          setSelectedAnswer(null);
        }
      })
      .catch((error) => {
        console.log(error, "error fof video");
      });
  };
  const submitFinalAnswer = (answered = false, timeout = false) => {
    if (!answered) {
      if (timeout) return props.history.push("/question");

      return setToast({
        msg: "Please answer atleast one test question !",
        type: "error",
      });
    }
    // /review
    const data = { practice_id: id, attempt_number: countId };
    localStorage.setItem("practice_id", id);
    localStorage.setItem("attempt_number", countId);
    apiCallToken
      .post(`/student/final/submit`, data)
      .then((response) => {
        var final_data = response.data;
        var test = response.data.data.question_ans_list;
        var hello = test.filter(
          (v, i, a) => a.findIndex((t) => t.q_id === v.q_id) === i
        );
        var js = hello.sort(
          (a, b) => parseFloat(a.price) - parseFloat(b.price)
        );
        final_data.data.question_ans_list = js;
        const newData = JSON.stringify(final_data);
        // console.log(newData, "data in local");
        localStorage.setItem("final_data", newData);
        props.history.push(`/review/${id}/${countId}`);
      })
      .catch((error) => {
        console.log(error, "error fof video");
        alert("Something went wrong");
      });
  };

  const setActiveList = (position, id, _map_id, idx) => {
    const selectedAns = parseInt(_map_id);
    if (selectedAnswerArray[count - 1]) {
      let answArr = selectedAnswerArray[count - 1];
      if (questionArray[count - 1].answer_display_type === "checkbox") {
        if (answArr.includes(selectedAns))
          answArr.splice(answArr.indexOf(selectedAns), 1);
        else answArr.push(selectedAns);
      } else {
        answArr = answArr.filter((a) => !tempOptions[idx].includes(a));
        answArr = [...answArr, selectedAns];
      }
      let allAnsArr = selectedAnswerArray;
      allAnsArr.splice(count - 1, 1, answArr);
      setSelectedAnswerArray(allAnsArr);
    } else setSelectedAnswerArray([...selectedAnswerArray, [selectedAns]]);

    var result = optionsArray[idx].map((el, i) => {
      if (questionArray[count - 1].answer_display_type === "checkbox") {
        if (el.id === id) {
          return { ...el, checked: el.checked ? false : true };
        } else return el;
      } else return { ...el, checked: el.id === id ? true : false };
    });
    var new_array = [...optionsArray];
    new_array[idx] = result;
    setOptionsArray([...new_array]);

    // var concat_array = [];
    // optionsArray.forEach((element) => {
    //   concat_array.push(...element);
    // });
    // setArrayList(concat_array);
    setSelectedAnswer(_map_id);
    // var myStoreId = [0, 2];
    // myStoreId.splice(idx, 0, id);
    // setStoreId(myStoreId);
  };

  const setOptionsCheck = (questionArray, optionUOList, question_count) => {
    // console.log(
    //   questionArray,
    //   optionUOList,
    //   question_count,
    //   "questionArray, optionList, question_count"
    // );
    optionUOList.sort(
      (a, b) => parseFloat(a.option_mapping) - parseFloat(b.option_mapping)
    );
    var optionList = optionUOList.map((option) => {
      let checked = false;
      if (selectedAnswerArray[question_count])
        checked = selectedAnswerArray[question_count].some(
          (s) => s == option.option_mapping
        );
      return { ...option, checked };
    });

    var questionList = questionArray[question_count];
    if (questionList && optionList.length !== 0) {
      var option_count = parseInt(questionList.no_of_options_set);
      var option_array = questionList.no_of_options.split(",");

      var arrayOfNumbers = option_array.map(Number);
      var added = 0;
      var arrays = [];
      arrayOfNumbers.forEach((element, index) => {
        var p1 = optionList.slice(added, added + element);
        added = added + element;
        arrays = [...arrays, p1];
      });
      setOptionsArray(arrays);
    }
  };

  // console.log(selectedAnswerArray, "selectedAnswerArray");
  // console.log(questionArray,currentCount, selectedAnswerArray, "option checked");
  return (
    <div style={{ marginTop: "150px", marginBottom: "200px" }}>
      <div id="snackbar" className={toast.type ? toast.type + " show" : ""}>
        {toast.msg}
      </div>
      <div
        style={{
          width: "82%",
          margin: "0px auto 30px",
          backgroundColor: "#fff",
        }}
        className="right-nav-details"
      >
        {/* <p className="nav-details-h">
          <b> Video List </b>
        </p> */}
        <InsideAccordian _data={videoList} _title="Video List" type="video" />
      </div>

      <div className="qa-c-l01">
        <div className="question-totalnum-c">
          <h3 style={{ margin: 0 }}>{practiceInfo.practice_name}</h3>
        </div>
        {practiceInfo.time_limit && (
          <div
            style={{ marginBottom: 20, borderBottom: "2px solid #f34325" }}
            className="timer-float-cla"
          >
            <StopWatch
              type={false}
              questionCount={practiceInfo.time_limit}
              timeLimitSubmit={() => submitFinalAnswer(answered, true)}
            />
            <p style={{ margin: "5px 0 10px" }}>
              Time Limit:{" "}
              <span style={{ fontWeight: "bold" }}>
                {practiceInfo.time_limit + " "}
                minutes
              </span>
            </p>
          </div>
        )}
        <div className="question-totalnum-c">
          <p>
            {" "}
            {count} out of {questionArray.length}
          </p>
        </div>
        <div className="timer-float-cla">
          <StopWatch questionCount={count} />
        </div>
        {count <= questionArray.length && (
          <p>
            Q.{count}){" "}
            <span
              dangerouslySetInnerHTML={{
                __html: `${questionArray[count - 1]?.question_desc || ""}`,
              }}
            ></span>
          </p>
        )}

        {/* <CheckAnswer questionCount={count} displayOptions={questionOptions} _item={questionArray[count-1]}/> */}
        <div className="checklist-answer-c">
          {optionsArray?.length !== 0 &&
            optionsArray.map((item, idx) => {
              return (
                <div key={idx}>
                  {item.map((newItem, newIdx) => {
                    return (
                      <label
                        key={newIdx}
                        className="container container-fc-if hide-in"
                        style={{
                          padding: "0px 0px 0px 40px",
                          marginBottom: "10px",
                        }}
                        onChange={() =>
                          setActiveList(
                            "first",
                            newItem.id,
                            newItem.option_mapping,
                            idx
                          )
                        }
                      >
                        <span
                          className="optionsBx"
                          dangerouslySetInnerHTML={{
                            __html: `${newItem.options_val}`,
                          }}
                        ></span>
                        <input
                          type={
                            questionArray[count - 1]?.answer_display_type ===
                            "checkbox"
                              ? "checkbox"
                              : "radio"
                          }
                          checked={selectedAnswerArray[count - 1]?.includes(
                            parseInt(newItem?.option_mapping)
                          )}
                        />
                        <span
                          className={
                            questionArray[count - 1]?.answer_display_type ===
                            "checkbox"
                              ? "checkmark"
                              : "dotmark"
                          }
                        ></span>
                      </label>
                    );
                  })}
                </div>
              );
            })}
        </div>
      </div>
      <div className="button-container-c">
        {count !== 1 && (
          <button
            className="btn btn-md btn-primary"
            onClick={() => counter("prev", count)}
          >
            Previous
          </button>
        )}
        &nbsp;
        {count !== questionArray.length && (
          <button
            className="btn btn-md btn-primary"
            onClick={() => counter("next", count)}
          >
            Next
          </button>
        )}
        {count === questionArray.length && (
          <button
            className="btn btn-md btn-primary"
            onClick={() => counter("next", count)}
          >
            Submit
          </button>
        )}
      </div>
    </div>
  );
}

export default withRouter(Testn);
