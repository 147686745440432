import React, { useState, useEffect, useContext } from "react";
import "./CheckAnswer.css";
import { UserCredsContext } from "../../ContextApis/UserCredsContext/UserCredsContext";

function CheckAnswer({ questionCount, displayOptions, _item, ...props }) {
  const {
    saveAnswer,
    questionChange,
    set_changeQuestion,
    currentCount,
    answeredArray,
    setSelectedAnswer,
  } = useContext(UserCredsContext);
  const [arrayList, setArrayList] = useState([]);
  const [optionsArray, setOptionsArray] = useState([]);
  useEffect(() => {
    if (currentCount > answeredArray.length) {
      setArrayList(displayOptions);
    } else {
      setArrayList(answeredArray[currentCount - 1]);
    }
  }, [displayOptions, answeredArray]);
  useEffect(() => {
    // if (currentCount != answeredArray.length) return
   
    // console.log(currentCount - 1, arrayList, "currentCount");
    if (currentCount <= answeredArray.length + 1) return;
    if (questionChange.next == true) {
      saveAnswer(arrayList);
      set_changeQuestion("next", false);
    }
  }, [questionChange, answeredArray]);

  const setActiveList = (position, id, _map_id, idx) => {
    var myArray;
    setSelectedAnswer(_map_id);
    var result = optionsArray[idx].map((el) => {
      return el.id == id ? { ...el, checked: true } : { ...el, checked: false };
    });
    var new_array = [...optionsArray];
    new_array[idx] = result;
    setOptionsArray(new_array);
    var concat_array = [];
    optionsArray.forEach((element) => {
      concat_array.push(...element);
    });
    // console.log(concat_array,'concat_arrayconcat_array')
    setArrayList(concat_array);
  };

  useEffect(() => {
    if (_item !== undefined && displayOptions.length !== 0) {
      // console.log(_item,'_item_item')
      var option_count = parseInt(_item.no_of_options_set);
      var option_array = _item.no_of_options.split(",");
      var arrayOfNumbers = option_array.map(Number);
      var added = 0;
      var arrays = [];
      arrayOfNumbers.forEach((element, index) => {
        var p1 = displayOptions.slice(added, added + element);
        added = added + element;
        arrays = [...arrays, p1];
      });
      setOptionsArray(arrays);
    }
  }, [_item, displayOptions]);
  // console.log(answeredArray, "arrayList");
  return (
    <div className="checklist-answer-c">
      {optionsArray != undefined &&
        optionsArray.length != 0 &&
        optionsArray.map((item, idx) => {
          // console.log(item, "item sandesh");
          return (
            <div>
              {item.map((newItem, newIdx) => {
                return (
                  <label
                    class="container container-fc-if hide-in"
                    style={{ padding: "0px 0px 0px 30px" ,marginBottom:'10px'}}
                    onClick={() =>
                      setActiveList(
                        "first",
                        newItem.id,
                        newItem.option_mapping,
                        idx
                      )
                    }
                  >
                    <span
                      dangerouslySetInnerHTML={{
                        __html: `${newItem.options_val}`,
                      }}
                    ></span>
                    <input type="checkbox" checked={newItem.checked} />
                    <span class="checkmark"></span>
                  </label>
                );
              })}
            </div>
          );
        })}
      {/* <div>
                {arrayListS.map((item, idx) => {
                    return (
                        <label class="container container-fc-if hide-in" style={{ paddingLeft: '32px' }} onClick={() => setActiveList('second', item.id)}>{item.name}
                            <input type="checkbox" checked={item.checked} />
                            <span class="checkmark"></span>
                        </label>
                    )
                })}
            </div> */}
    </div>
  );
}

export default CheckAnswer;
